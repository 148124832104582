import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectProfilesDtlsData } from 'src/app/store/common.selectors';
import { AuthUserData, RbacPolicies } from 'src/app/appdata/auth.model';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-view-reports',
  templateUrl: './view-reports.component.html',
  styleUrls: ['./view-reports.component.scss'],
})
export class ViewReportsComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
  displayTemplate = new BehaviorSubject<TemplateRef<any>>(null);
  searchData: string = null;
  destroy$: Subject<boolean> = new Subject<boolean>();
  allCategories = [
    { name: 'Marketing', value: 'marketing' },
    { name: 'New Report', value: 'newreport' },
    { name: 'NUll', value: 'null' },
  ];
  permissions: RbacPolicies;
  displayedColumns: string[] = ['name', 'category'];
  userDetails: AuthUserData;
  active = 1;
  page = 1;
  pageSize = 10;

  @ViewChild('noReportsTemp') private noReportsTemp: TemplateRef<any>;
  @ViewChild('showReportsTemp') private showReportsTemp: TemplateRef<any>;

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private readonly store: Store,
    private shareservice: SharedService
  ) {}

  ngOnInit(): void {
    this.subscribeToProfileData();

    if(history.state.Navigation) {
      this.active = history.state.Navigation;
    }
  }

  subscribeToProfileData() {
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.loggedInUserDetails) {
          this.userDetails = res.loggedInUserDetails;
          this.permissions = this.userDetails.profile.acl.rbac.rbac_policies;
        }
      });
  }

  createReport() {
    this.router.navigate([
      'customer',
      this.userDetails.profile.id,
      'reports',
      'create',
    ]);
  }
 
  createTemplate() {
    this.router.navigate([
      'customer',
      this.userDetails.profile.id,
      'reports',
      'create-template',
    ]);
  }

  searchName(data: string) {
    if (data && data.length > 2) {
      return;
    }
  }

  onSearchNameChange(event: any) {
    if (!event) {
      return;
    } else {
      this.searchName(event);
    }
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    return;
  }

  ngAfterViewInit(): void {
    this.displayTemplate.next(this.showReportsTemp);
    this.cd.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.shareservice.unsubscribe();
    this.shareservice.datePickerObject.next(null);
    this.shareservice.customDateAction.next(null);
  }
}
