import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { CustomValidators } from 'src/app/services/CustomValidators';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { selectProfilesDtlsData } from 'src/app/store/common.selectors';
import {
  AuthUserData,
  ChannelCredentials,
  RbacPolicies,
} from 'src/app/appdata/auth.model';
import { SharedService } from 'src/app/services/shared.service';
import { SelectionModel } from '@angular/cdk/collections';
import * as reportactions from 'src/app/customer/reports/store/reports.actions';
import {
  ReportTemplateCreatePayload,
  ReportsDatum,
} from 'src/app/services/reports.service';
import { selectReportDetails } from '../store/reports.selectors';
import { AlertService } from 'src/app/alert/alert.service';

export interface Properties {
  isSelected: boolean;
  value: string;
}

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.scss'],
})
export class CreateTemplateComponent implements OnInit, OnDestroy {
  createTemplateForm: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  showAllParameters = false;

  tempCharCount = 0;
  maxCharCount = 16;

  templateTypeList = [{ name: 'Campaign', value: 'campaign' }];
  tempCategories = [
    { name: 'Marketing', value: 'marketing' },
    { name: 'Utility', value: 'utility' },
    { name: 'Authentication', value: 'authentication' },
  ];
  selection = new SelectionModel<Properties>(true, []);
  reportVariableData: ReportTemplateCreatePayload = {
    account_id: null,
    bsp_id: null,
    channel: null,
    waba_number: null,
    created_by: null,
    template_name: null,
    category: null,
    report_type: null,
    properties: [],
  };
  clonetemplate: any;
  displayedColumns: string[] = [
    'report_name',
    'creation_date',
    'date_range',
    'report_type',
    'category',
    'report_properties',
    'campaign_ids',
    'created_by',
    'status',
  ];
  channel_credentails: ChannelCredentials;
  reportsList: ReportsDatum[] = [];
  channelSub: Subscription;
  userDetails: AuthUserData;
  permissions: RbacPolicies;
  selectedparameters: string[] = [];
  templateparameterslist: Properties[] = [
    { isSelected: false, value: 'waba_number' },
    { isSelected: false, value: 'campaign_id' },
    { isSelected: false, value: 'template_id' },
    { isSelected: false, value: 'template_name' },
    { isSelected: false, value: 'campaign_name' },
    { isSelected: false, value: 'msisdn' },
    { isSelected: false, value: 'status' },
    { isSelected: false, value: 'message_id' },
    { isSelected: false, value: 'submitted_at' },
    { isSelected: false, value: 'sent_at' },
    { isSelected: false, value: 'delivered_at' },
    { isSelected: false, value: 'read_at' },
  ];

  constructor(
    private fb: FormBuilder,
    private cv: CustomValidators,
    private router: Router,
    private alertMsg: AlertService,
    private activatedRoute: ActivatedRoute,
    private shareservice: SharedService,
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.subscribeToBA();
    this.createTemplateForm = this.fb.group({
      templateName: [
        null,
        [Validators.required, Validators.pattern(this.cv.nameRegex)],
      ],
      tempType: ['campaign', Validators.required],
      tempCategory: [null, Validators.required],
      tempParameters: [[], Validators.required],
    });

    this.createTemplateForm.get('templateName').valueChanges.subscribe((t) => {
      this.tempCharCount = t ? t.length : 0;
    });

    this.subscribeToRouting();
    this.subscribeToProfileData();
    this.subscribeToTemplatesData();
  }

  subscribeToRouting() {
    if (this.router.url.includes('clone')) {
      this.activatedRoute.params.subscribe((params: Params) => {
        const templatename = params['name'];
        const get_payload = {
          template_name: templatename,
          account_id: this.channel_credentails.account_id,
          wabano: this.channel_credentails.waba_number,
          channel: this.channel_credentails.channel_name.toLowerCase(),
        };
        this.store.dispatch(
          reportactions.reporttemplates.reportTemplatesList({
            payload: get_payload,
          })
        );
      });
    }
  }

  subscribeToProfileData() {
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.loggedInUserDetails) {
          this.userDetails = res.loggedInUserDetails;
          this.reportVariableData.created_by =
            this.userDetails.profile.first_name +
            ' ' +
            this.userDetails.profile.last_name;
          this.permissions = this.userDetails.profile.acl.rbac.rbac_policies;
        }
      });
  }

  subscribeToBA() {
    this.channelSub = this.shareservice.selectedBA$.subscribe((value) => {
      if (value) {
        this.channel_credentails = value.channel_credentials;
        this.reportVariableData = JSON.parse(
          JSON.stringify(this.reportVariableData)
        );
        this.reportVariableData.account_id =
          value.channel_credentials.account_id;
        this.reportVariableData.bsp_id = value.channel_credentials.bsp_id;
        this.reportVariableData.channel = value.channel_credentials.channel;
        this.reportVariableData.waba_number =
          value.channel_credentials.waba_number;
      }
    });
  }

  subscribeToTemplatesData() {
    this.store
      .select(selectReportDetails)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.createreporttemplate) {
          if (res.createreporttemplate.status_code === 200) {
            this.alertMsg.alertSuccess(
              'Success!',
              res.createreporttemplate.message
            );
            this.gotoListing();
          }
        } else if (res.reporttemplateslist) {
          if (res.reporttemplateslist.status_code === 200) {
            this.clonetemplate = res.reporttemplateslist.data.templates_data[0];
            this.createTemplateForm
              .get('tempCategory')
              .setValue(this.clonetemplate.category);

            const properties = JSON.parse(this.clonetemplate.properties);
            if (properties.length > 0) {
              this.templateparameterslist = [];
              properties.forEach((t: string) =>
                this.templateparameterslist.push({
                  isSelected: false,
                  value: t,
                })
              );
            } else {
              this.templateparameterslist = [];
            }
            this.toggleAllRows();
            this.addParametersListToForm();
          } else {
            this.clonetemplate = null;
          }
        } else if (res.error && res.error.message) {
          this.alertMsg.alertDanger('Error!', res.error.message);
        }
      });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.templateparameterslist.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.addParametersListToForm();
      return;
    }

    this.selection.select(...this.templateparameterslist);
    this.addParametersListToForm();
  }

  addParametersListToForm() {
    const selected_data: string[] = [];
    this.selection.selected.forEach((t) => selected_data.push(t.value));
    this.createTemplateForm.get('tempParameters').setValue(selected_data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Properties): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.value + 1
    }`;
  }

  get ctFC() {
    return this.createTemplateForm.controls;
  }

  gotoListing() {
    this.router.navigate(
      ['customer', this.userDetails.profile.id, 'reports'],
      {state : {
        Navigation: 2,
      }}
    );
    this.createTemplateForm.reset();
  }

  onSaveTemplate(form: FormGroup) {
    if (form.invalid) {
      return;
    }
    this.reportVariableData = JSON.parse(
      JSON.stringify(this.reportVariableData)
    );
    this.reportVariableData.template_name = form.value.templateName;
    this.reportVariableData.category = form.value.tempCategory;
    this.reportVariableData.report_type = form.value.tempType;
    this.reportVariableData.properties = form.value.tempParameters;
    this.store.dispatch(
      reportactions.reporttemplates.createReportTemplate({
        payload: this.reportVariableData,
      })
    );
  }

  ngOnDestroy() {
    this.channelSub.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
