<div id="headerMain">
  <header id="header" class="navbar-fixed">
    <div
      class="navbar navbar-expand-lg navbar-flush navbar-container navbar-bordered py-2"
    >
      <div class="navbar-nav-wrap">
        <!-- Brand Logo -->
        <div class="navbar-brand-wrapper col-sm-2">
          <!-- Logo -->
          <a class="navbar-brand" aria-label="WABA Customer Logo">
            <img
              class="navbar-brand-logo"
              [src]="whiteLabeledData?.logo"
              alt="Logo"
              width="200px"
              [routerLink]="
                activeaccounts.length === 0
                  ? null
                  : '/customer/' + this.userDetails.profile.id + '/dashboard'
              "
            />
          </a>
          <!-- End Logo -->
        </div>
        <!-- Brand Logo -->

        <!-- Secondary Content -->
        <div class="navbar-nav-wrap-content-right col-lg-auto col-sm-10">
          <!-- Navbar -->
          <ul class="navbar-nav align-items-center flex-row">
            <!-- Channel Selection -->
            <li
              class="nav-item d-sm-inline-block"
              *ngIf="ForModule === 'Control-Center'"
            >
              <ng-select
                [items]="channelsList"
                bindLabel="ch_name"
                bindValue="ch_name"
                autofocus
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="false"
                [compareWith]="compareFn"
                placeholder="Select Channel"
                [clearable]="false"
                [(ngModel)]="selectedChannel"
                class="hdr_ba_channel"
              >
                <!-- Select Option -->
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <img
                    class="img-fluid w-15"
                    [src]="item.ch_icon"
                    [alt]="item.ch_name"
                  />
                  <ng-container
                    *ngIf="
                      item.ch_name === 'WhatsApp Business API';
                      else otherChannels
                    "
                  >
                    WhatsApp
                  </ng-container>
                  <ng-template #otherChannels>
                    {{ item.ch_name }}
                  </ng-template>
                </ng-template>
                <!-- END Select Option -->

                <!-- Select Label -->
                <ng-template ng-label-tmp let-item="item">
                  <img
                    class="img-fluid w-15"
                    [src]="item.ch_icon"
                    [alt]="item.ch_name"
                  />
                  <ng-container
                    *ngIf="
                      item.ch_name === 'WhatsApp Business API';
                      else otherChannels
                    "
                  >
                    WhatsApp
                  </ng-container>
                  <ng-template #otherChannels>
                    {{ item.ch_name }}
                  </ng-template>
                </ng-template>
                <!-- END Select Label -->
              </ng-select>
            </li>
            <!-- END Channel Selection -->

            <!-- BSP Selection -->
            <!-- <li
              class="nav-item d-sm-inline-block d-none"
              *ngIf="ForModule === 'Customer'"
            >
              <ng-select
                class="header_select"
                [items]="bsp"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="false"
                placeholder="Select BSP"
                [clearable]="false"
                [(ngModel)]="selectedBsp"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <span>{{item === 'RML'?'Routemobile':item}}</span>
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                  <span>{{item === 'RML'?'Routemobile':item}}</span>
                </ng-template>
              </ng-select>
            </li> -->
            <!-- END BSP Selection -->

            <!-- Business Account Selection -->
            <li
              class="nav-item d-sm-inline-block"
              *ngIf="ForModule === 'Customer'"
            >
              <ng-select
                class="header_select"
                [items]="activeaccounts"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="false"
                placeholder="Select Business Account"
                [clearable]="false"
                [(ngModel)]="selectedBA"
                (ngModelChange)="onSelectBA($event)"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="view-bact d-flex align-items-center">
                    <!-- Business Account Default Avatar -->
                    <span class="avatar avatar-circle avatar-sm mr-2">
                      <img
                        class="avatar-img"
                        [src]="item.channel_credentials.profile_pic"
                        [alt]="item.channel_credentials.business_name"
                      />
                    </span>
                    <!-- END Business Account Default Avatar -->

                    <div class="ba_details">
                      <h5 class="mb-0">
                        {{ item.channel_credentials.waba_number }}
                      </h5>
                      <small class="text-muted">
                        {{ item.channel_credentials.business_name }}
                      </small>
                    </div>
                  </div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                  <div class="view-bact d-flex align-items-center">
                    <!-- Business Account Default Avatar -->
                    <span class="avatar avatar-circle avatar-sm mr-2">
                      <img
                        class="avatar-img"
                        [src]="item.channel_credentials.profile_pic"
                        [alt]="item.channel_credentials.business_pame"
                      />
                    </span>
                    <!-- END Business Account Default Avatar -->

                    <div class="ba_details">
                      <h5 class="mb-0">
                        {{ item.channel_credentials.waba_number }}
                      </h5>
                      <small class="text-muted">
                        {{ item.channel_credentials.business_name }}
                      </small>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
            </li>
            <!-- END Business Account Selection -->

            <!-- Notifications -->
            <li class="nav-item d-sm-inline-block d-none">
              <div class="cst_notifications">
                <div
                  class="nav-item"
                  ngbDropdown
                  display="dynamic"
                  placement="bottom-end"
                >
                  <button
                    type="button"
                    class="btn btn-icon btn-ghost-secondary rounded-circle ml-1 position-relative"
                    matTooltip="Notifications"
                    matTooltipPosition="below"
                    id="dropdownBasic1"
                    ngbDropdownToggle
                  >
                    <!-- For Bell Animation on Notification add class "bell_animate" -->
                    <em class="bi bi-bell"></em>
                    <span
                      class="alert_dot"
                      [matBadge]="notificationsMsgs.length"
                      matBadgeOverlap="false"
                    ></span>
                  </button>

                  <!-- Notifications Dropdown -->
                  <div
                    class="notification-menu-dropdown"
                    ngbDropdownMenu
                    aria-labelledby="dropdownBasic1"
                  >
                    <div class="card p-0">
                      <div class="card-header p-3">
                        <h3 class="title">Notifications</h3>
                      </div>

                      <div class="card-body p-0">
                        <!-- Nav -->
                        <ul
                          ngbNav
                          #nav="ngbNav"
                          [(activeId)]="active"
                          class="nav nav-tabs notification-nav-tabs nav-justified"
                        >
                          <!-- Messages -->
                          <li [ngbNavItem]="1" class="nav-item">
                            <button ngbNavLink>Messages (3)</button>
                            <ng-template ngbNavContent>
                              <ul
                                class="list-group list-group-flush navbar-card-list-group notification_list"
                              >
                                <!-- Item -->
                                <li
                                  class="list-group-item"
                                  [ngClass]="
                                    unread ? 'unread_notification_bg' : ''
                                  "
                                  *ngFor="let item of notificationsMsgs"
                                >
                                  <div class="row">
                                    <div class="col-auto position-static">
                                      <div class="d-flex align-items-center">
                                        <div
                                          class="avatar avatar-md avatar-circle"
                                        >
                                          <img
                                            class="avatar-img"
                                            [src]="item.image"
                                            alt="Description"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col ml-n3">
                                      <span class="card-title h5">{{
                                        item.name
                                      }}</span>
                                      <p class="card-text font-size-sm">
                                        <span [innerHTML]="item.message"></span>
                                      </p>
                                    </div>
                                    <small class="col-auto text-muted">{{
                                      item.time
                                    }}</small>
                                  </div>
                                </li>
                                <!-- End Item -->
                              </ul>
                            </ng-template>
                          </li>
                          <!-- Messages -->

                          <!-- Archives -->
                          <li [ngbNavItem]="2" class="nav-item">
                            <button ngbNavLink>Archives</button>
                            <ng-template ngbNavContent> </ng-template>
                          </li>
                          <!-- Archives -->
                        </ul>
                        <!-- End Nav -->

                        <!-- Body -->
                        <div class="card-body-height">
                          <!-- Tab Content -->
                          <div class="tab-content" id="notificationTabContent">
                            <div [ngbNavOutlet]="nav" class="mt-2"></div>
                          </div>
                        </div>
                      </div>

                      <div class="card-footer border-top text-center p-0 py-2">
                        <button class="btn btn-link all-notification-btn">
                          View all Notifications
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- End Notifications Dropdown -->
                </div>
              </div>
            </li>
            <!-- End Notifications -->

            <!-- Account -->
            <li class="nav-item">
              <div class="hs-unfold">
                <a
                  class="navbar-dropdown-account-wrapper"
                  [matMenuTriggerFor]="profileSettingsMenu"
                >
                  <div
                    class="d-flex align-items-center justify-content-center gap-2"
                  >
                    <div class="avatar avatar-sm avatar-circle">
                      <ng-container
                        *ngIf="
                          userDetails.profile.profile_image_url;
                          else defaultImg
                        "
                      >
                        <img
                          class="avatar-img"
                          src="{{ userDetails.profile.profile_image_url }}"
                          alt="{{ userDetails.profile.first_name }}{{
                            userDetails.profile.last_name
                          }}"
                        />
                      </ng-container>
                      <ng-template #defaultImg>
                        <img
                          class="avatar-img"
                          src="assets/img/customer/default_avatar.jpg"
                          alt="User Default Avatar"
                        />
                      </ng-template>
                    </div>
                    <span class="c_name"
                      >{{ userDetails.profile.first_name | titlecase }}
                      {{ userDetails.profile.last_name | titlecase }}</span
                    >
                  </div>
                </a>
                <!-- User Account Dropdown -->
                <mat-menu #profileSettingsMenu="matMenu">
                  <div
                    id="accountNavbarDropdown"
                    class="navbar-dropdown-account"
                  >
                    <div class="dropdown-item-text">
                      <div class="act_managed">
                        <div>This account is managed by</div>
                        <span>
                          <b>{{ userDetails.account.account_name }}</b>
                        </span>
                      </div>
                      <div class="dropdown-divider"></div>
                      <div class="profile_details">
                        <div class="avatar avatar-circle">
                          <ng-container
                            *ngIf="
                              userDetails.profile.profile_image_url;
                              else defaultImg
                            "
                          >
                            <img
                              class="avatar-img"
                              src="{{ userDetails.profile.profile_image_url }}"
                              alt="{{ userDetails.profile.first_name }} {{
                                userDetails.profile.last_name
                              }}"
                            />
                          </ng-container>
                          <ng-template #defaultImg>
                            <img
                              class="avatar-img"
                              src="assets/img/customer/default_avatar.jpg"
                              alt="Default Avatar"
                            />
                          </ng-template>
                          <span
                            class="p-1 border border-light rounded-circle text-success status_dot"
                          ></span>
                        </div>
                        <div class="flex-grow-1 ms-2">
                          <h5 class="mb-0 text-capitalize">
                            {{ userDetails.profile.first_name | titlecase }}
                            {{ userDetails.profile.last_name | titlecase }}
                          </h5>
                          <small class="card-text">
                            {{ userDetails.profile.email }}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div
                      class="dropdown-divider"
                      *ngIf="permissions.control_centre"
                    ></div>
                    <div class="role_status" *ngIf="permissions.control_centre">
                      <div class="role_name">
                        <em class="bi bi-building-gear"></em>
                        <h5>Org Admin</h5>
                      </div>
                      <button
                        class="btn btn-sm btn-primary"
                        [routerLink]="
                          '/cc/' + this.userDetails.profile.id + '/dashboard'
                        "
                        [disabled]="activeaccounts.length === 0"
                      >
                        Manage
                      </button>
                    </div>
                    <div class="dropdown-divider"></div>

                    <!-- Manage Products Screen Menu Option -->
                    <button
                      mat-menu-item
                      [routerLink]="
                        '/customer/' + this.userDetails.profile.id + '/product'
                      "
                    >
                      <em class="bi bi-sliders"></em> Manage Products
                    </button>
                    <!-- END Manage Products Screen Menu Option -->

                    <!-- Header My Profile Menu Option -->
                    <button
                      mat-menu-item
                      [routerLink]="
                        '/customer/' +
                        this.userDetails.profile.id +
                        '/my-profile'
                      "
                      [disabled]="activeaccounts.length === 0"
                    >
                      <em class="bi bi-person"></em>
                      My Profile
                    </button>
                    <!-- END Header My Profile Menu Option -->

                    <!-- Header Signout Option -->
                    <button
                      mat-menu-item
                      (click)="openVerticallyCentered(logoutModal)"
                    >
                      <em class="bi-box-arrow-right"></em> Sign out
                    </button>
                    <!-- END Header Signout Option -->
                  </div>
                </mat-menu>
                <!-- End User Account Dropdown -->
              </div>
            </li>
            <!-- End Account -->
          </ul>
          <!-- End Navbar -->
        </div>
        <!-- End Secondary Content -->
      </div>
    </div>
    <!-- License Expiry Alerts -->
    <div
      class="alert alert-warning alert-dismissible fade show fw-bold license_expiry"
      role="alert"
      *ngIf="noOfDays <= 15"
    >
      <i class="bi-calendar3 me-2" aria-label="Info:"></i> Your Organization
      License expires in {{ noOfDays }} days
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        (click)="showLicenseAlert = !showLicenseAlert"
      ></button>
    </div>
    <!-- END License Expiry Alerts -->
  </header>
</div>

<!-- Logout Modal Box -->
<ng-template #logoutModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Log out</h4>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <div class="model_icon">
        <em class="bi-trash text-danger"></em>
      </div>
      <h4>Are you sure you wish to Log out</h4>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="d('Cross click')">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="signout()">
      Confirm
    </button>
  </div>
</ng-template>
<!-- End Logout Modal Box -->
