import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { selectProfilesDtlsData } from '../store/common.selectors';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit, OnDestroy {
  currenturl: string;
  userDetails: any;
  showSidenav: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public router: Router, 
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.loggedInUserDetails) {
          this.userDetails = res.loggedInUserDetails;
        }
      });
    
    if(this.router.url.includes('channel') || this.router.url.includes('product'))
    {
      this.showSidenav = false;
    }
    else{
      this.showSidenav = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
