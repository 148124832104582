<!-- Loader -->
<div class="center">
  <span class="loader" *ngIf="loader.visibility | async"></span>
</div>
<div class="spinner-overlay" *ngIf="loader.visibility | async"></div>
<!-- END Loader -->

<div class="card p-3">
  <div class="top_header_options">
    <div class="d-flex align-items-center gap-2">
      <!-- Goto Campaign Listing -->
      <button class="btn btn-sm btn-icon btn-light" (click)="gotoListing()">
        <em class="bi bi-chevron-left"></em>
      </button>
      <!-- END Goto Campaign Listing -->

      <h3 class="main_title">Setup Campaign</h3>
    </div>

    <div class="wabaid_details">
      <div class="quality_rating item_detail">
        <p>
          Quality Rating
          <em
            class="bi-info-circle"
            matTooltip="Your WhatsApp Business Account's quality rating (as assessed by WhatsApp) is High, as the messages that you have been sending to your customers in the last 7 days have been of good quality."
          ></em>
        </p>
        <span
          class="badge"
          [ngClass]="{
            'badge-success' : channel_credentails.waba_quality.toLowerCase() === 'high',
            'badge-warning' : channel_credentails.waba_quality.toLowerCase() === 'medium',
            'badge-danger' : channel_credentails.waba_quality.toLowerCase() === 'low'
          }"
          >{{ channel_credentails.waba_quality }}</span
        >
      </div>
      <div class="tier_status item_detail">
        <p>
          Template Messaging Tier
          <em
            class="bi-info-circle"
            matTooltip="Messaging limits determine how many unique users your business can send template messages on a daily basis."
          ></em>
        </p>
        <h5>{{ channel_credentails.messaging_limit }}</h5>
      </div>
    </div>
  </div>
</div>

<div class="card card-vh-100 p-0 mt-2">
  <div class="row m-0 justify-content-between">
    <!-- Campaign Setup Form -->
    <div class="col-xl-8 col-sm-12">
      <div class="setup_form_sec">
        <mat-stepper class="card_align_items" [linear]="isLinear" #stepper>
          <!-- Step 1 Setup Campaign -->
          <mat-step [stepControl]="campaignsetup">
            <ng-template matStepLabel>
              <p class="text-sm fw-bold">Setup Campaign</p>
            </ng-template>

            <form [formGroup]="campaignsetup">
              <div class="row">
                <mat-radio-group
                  aria-label="Select an option"
                  class="d-inline-flex"
                  formControlName="contacttype"
                >
                  <mat-radio-button value="singlecontact" checked
                    >Single Contact</mat-radio-button
                  >
                  <mat-radio-button value="multiplecontacts"
                    >Multiple Contacts</mat-radio-button
                  >
                </mat-radio-group>
              </div>
              <mat-divider class="my-2"></mat-divider>

              <!-- Single Contact Number -->
              <div
                class="form-group"
                *ngIf="
                  campaignsetup.get('contacttype').value === 'singlecontact'
                "
              >
                <label class="input-label" for="contactno"
                  >Contact Number
                  <span
                    class="text-danger"
                    matTooltip="Contact Number is mandatory"
                    >*</span
                  >
                </label>

                <div class="d-flex justify-content-between gap-2">
                  <!-- Dial Code Select -->
                  <ng-select
                    formControlName="countrycode"
                    placeholder="Select Country"
                    bindLabel="dialCode"
                    [items]="countries"
                    [clearable]="false"
                    [searchFn]="commonservice.customCountrySearchFn"
                    [compareWith]="compareFn"
                    [closeOnSelect]="true"
                    dropdownPosition="bottom"
                  >
                    <ng-template ng-option-tmp let-item="item">
                      +{{ item.dialCode }} {{ item.name }}
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                      +{{ item.dialCode }} {{ item.name }}
                    </ng-template>
                  </ng-select>
                  <!-- END Dial Code Select -->

                  <div class="camp_contact w-100">
                    <!-- Contact Number Input -->
                    <input
                      class="form-control d-block"
                      type="tel"
                      inputmode="numeric"
                      placeholder="Contact number"
                      formControlName="contactno"
                      id="contactno"
                      (keydown.arrowup)="(false)"
                      (keydown.arrowdown)="(false)"
                    />
                    <!-- END Contact Number Input -->

                    <!-- Contact Number Error -->
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        csFC['contactno'].touched || csFC['contactno'].dirty
                      "
                    >
                      <ng-container
                        *ngIf="
                          csFC['contactno'].value === '' ||
                            csFC['contactno'].value === null;
                          else fcInvalid
                        "
                      >
                        Contact number can not be empty
                      </ng-container>
                      <ng-template #fcInvalid>
                        <span *ngIf="csFC['contactno'].errors">
                          Enter valid phone number between 10-16 numbers.
                        </span>
                      </ng-template>
                    </div>
                    <!-- END Contact Number Error -->
                  </div>
                </div>
              </div>
              <!-- ENd Single Contact Number -->

              <!-- Country Dial Code -->
              <div
                class="form-group"
                *ngIf="
                  campaignsetup.get('contacttype').value === 'multiplecontacts'
                "
              >
                <span class="input-label label" for="countryDialCode"
                  >Country Dial Code
                </span>

                <!-- Dial Code Select -->
                <ng-select
                  formControlName="countrycode"
                  id="countryDialCode"
                  placeholder="Select Country"
                  bindValue="dialCode"
                  [items]="countries"
                  [clearable]="false"
                  [closeOnSelect]="true"
                  dropdownPosition="bottom"
                  [searchFn]="commonservice.customCountrySearchFn"
                >
                  <ng-template ng-option-tmp let-item="item">
                    +{{ item.dialCode }} {{ item.name }}
                  </ng-template>
                  <ng-template ng-label-tmp let-item="item">
                    +{{ item.dialCode }} {{ item.name }}
                  </ng-template>
                </ng-select>
                <!-- END Dial Code Select -->
              </div>
              <!-- ENd Country Dial Code -->

              <!-- Campaign Name -->
              <div class="form-group">
                <div class="d-flex align-items-center justify-content-between">
                  <label class="input-label" for="campaignName"
                    >Campaign Name
                    <span class="text-danger" matTooltip="Campaign name is mandatory"
                      >*</span
                    >
                  </label>
                  <p class="text-muted" *ngIf="charCount > 0">
                    {{ charCount }}/{{ maxCharCount }}
                  </p>
                </div>
                <input
                  class="form-control"
                  id="campaignName"
                  type="text"
                  [maxlength]="maxCharCount"
                  (blur)="checkCampaignName()"
                  placeholder="Enter here"
                  formControlName="campaignName"
                  [ngClass]="campaignNameExists ? 'invalid-input':''"
                />
                <!-- Campaign Name Error -->
                <div class="invalid-feedback">
                  <div
                    *ngIf="
                      csFC['campaignName'].touched || csFC['campaignName'].dirty
                    "
                  >
                    <ng-container
                      *ngIf="
                        csFC['campaignName'].value === '' ||
                          csFC['campaignName'].value === null;
                        else fcInvalid
                      "
                    >
                      Campaign name can not be empty.
                    </ng-container>
                    <ng-template #fcInvalid>
                      <span *ngIf="csFC['campaignName'].errors">
                        Please enter a valid campaign name.
                      </span>
                    </ng-template>
                  </div>

                  <span *ngIf="campaignNameExists">
                    Campaign name already exists.
                  </span>
                </div>
                <!-- END Campaign Name Error -->
              </div>
              <!-- ENd Campaign Name -->

              <!-- Campaign Tags -->
              <div class="form-group">
                <label class="input-label" for="campaignTags"
                  >Campaign Tags
                </label>
                <mat-form-field class="example-chip-list" appearance="outline">
                  <mat-chip-grid #chipGrid aria-label="Enter Tags">
                    <mat-chip-row
                      (removed)="removeKeyword(keyword)"
                      [editable]="true"
                      (edited)="edit(keyword, $event)"
                      *ngFor="let keyword of campaignTags"
                    >
                      {{ keyword }}
                      <button matChipRemove [aria-label]="'remove ' + keyword">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip-row>
                    <input
                      id="campaignTags"
                      type="text"
                      placeholder="Enter here"
                      [matChipInputFor]="chipGrid"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matChipInputAddOnBlur]="addOnBlur"
                      (matChipInputTokenEnd)="add($event)"
                    />
                  </mat-chip-grid>
                </mat-form-field>
              </div>
              <!-- ENd Campaign Tags -->

              <!-- Campaign Category -->
              <div class="form-group">
                <div class="input-label label"
                  >Campaign Category
                  <span
                    class="text-danger"
                    matTooltip="Campaign Category is mandatory"
                    >*</span
                  >
                </div>
                <ng-select
                  formControlName="camapaignCategory"
                  id="camapaignCategory"
                  bindLabel="name"
                  bindValue="name"
                  [items]="cmpCategory"
                  [clearable]="false"
                  [searchable]="false"
                  [compareWith]="compareFnCampaignCategory"
                  [closeOnSelect]="true"
                  placeholder="-- Select --"
                >
                </ng-select>
                <!-- Campaign category Error -->
                <div class="invalid-feedback">
                  <div
                    *ngIf="
                      csFC['camapaignCategory'].touched ||
                      csFC['camapaignCategory'].dirty
                    "
                  >
                    <ng-container
                      *ngIf="
                        csFC['camapaignCategory'].value === '' ||
                        csFC['camapaignCategory'].value === null
                      "
                    >
                      Campaign category can not be empty.
                    </ng-container>
                  </div>
                </div>
                <!-- END Campaign category Error -->
              </div>
              <!-- END Campaign Category -->

              <!-- Template Category -->
              <div class="form-group">
                <div class="input-label label"
                  >Template Category
                  <span
                    class="text-danger"
                    matTooltip="Template Category is mandatory"
                    >*</span
                  >
                </div>
                <ng-select
                  formControlName="templateCategory"
                  id="templateCategory"
                  bindLabel="name"
                  bindValue="name"
                  [items]="tempCategory"
                  (change)="detectChangeTemp($event)"
                  [clearable]="false"
                  [searchable]="false"
                  [closeOnSelect]="true"
                  [compareWith]="compareFnTemplateCategory"
                  placeholder="-- Select --"
                >
                </ng-select>
                <!-- Template category Error -->
                <div class="invalid-feedback">
                  <div
                    *ngIf="
                      csFC['templateCategory'].touched ||
                      csFC['templateCategory'].dirty
                    "
                  >
                    <ng-container
                      *ngIf="
                        csFC['templateCategory'].value === '' ||
                        csFC['templateCategory'].value === null
                      "
                    >
                      Template category can not be empty.
                    </ng-container>
                  </div>
                </div>
                <!-- END Template category Error -->
              </div>
              <!-- END Template Category -->

              <!-- Choose Template -->
              <div
                class="form-group"
                *ngIf="campaignsetup.get('templateCategory').value"
              >
                <span class="input-label label" for="chooseTemplate"
                  >Choose Template
                  <span
                    class="text-danger"
                    matTooltip="Choose Template is mandatory"
                    >*</span
                  >
                </span>

                <ng-select
                  formControlName="chooseTemplate"
                  id="chooseTemplate"
                  bindLabel="template_name"
                  bindValue="id"
                  [items]="templatesList$ | async"
                  [virtualScroll]="true"
                  [loading]="loading"
                  [compareWith]="compareFnTemplateName"
                  (change)="onChooseTemplate($event)"
                  (scrollToEnd)="onScrollToEnd($event)"
                  (search)="onSearch($event)"
                  [clearable]="false"
                  [closeOnSelect]="true"
                  placeholder="Choose Template"
                >
                  <ng-template ng-label-tmp let-item="item">
                    <!-- This template controls the label in the dropdown -->
                    {{ item.template_name }}
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    <!-- Conditional template -->
                    <ng-container
                      *ngIf="
                        (templatesList$ | async)?.length > 0;
                        else noDataTemplate
                      "
                    >
                      {{ item.template_name }}
                    </ng-container>
                  </ng-template>
                  <ng-template ng-notfound-tmp #noDataTemplate>
                    <!-- Template to display when there's no data -->
                    <div
                      class="no_template"
                      (click)="createTemplateNavigation()"
                      (keyup)="createTemplateNavigation()"
                    >
                      <button type="button" class="btn btn-sm btn-primary">
                        <em class="bi-plus-circle-fill"></em>
                      </button>
                      <div class="details">
                        <h5>Create new template</h5>
                        <span class="text-muted"
                          >There is no template to continue campaign</span
                        >
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <!-- END Choose Template -->

              <!-- Choosen Template Editing Options -->
              <div class="template_data my-3" *ngIf="selectedTemplate">
                <div class="guides mb-3 text-info-emphasis text-sm">
                  <h5 class="main_title">Note:</h5>
                  <ol class="text-start px-3 m-0">
                    <li *ngIf="campaignsetup.get('contacttype').value === 'singlecontact'">
                      For single contact, enter the actual values for the
                      variables to display in the template.
                    </li>
                    <ng-container *ngIf="campaignsetup.get('contacttype').value === 'multiplecontacts'">
                      <li>If you're sending to multiple contacts, make sure the column headers in your CSV file match the variable names you see in our system. 
                      </li>
                      <li>
                        For images, videos, and documents, you can remove the existing file by clicking the "x" and entering the corresponding CSV header name in the "File URL" section. <br/>
                        <strong><u>Note:</u></strong> Enter variables as plain text only eg; Link, First Name etc. Do not enter any variable name/CSV header in curly braces "<span>&#123;&#123;</span>&nbsp;&nbsp;<span>&#125;&#125;</span>" or other special characters.
                      </li>
                      <li>
                        <strong>Country Codes :</strong> Only append the "Country Code" to the "Mobile" column in your bulk file if you didn't select it during the "Campaign Setup" step. eg; for India add 91, ie; 91XXX***XXX
                      </li>
                    </ng-container>
                    <li>
                      <strong>Messaging Limits :</strong> Please check your daily messaging limit on the profile
                      management page before executing your campaign.
                    </li>
                  </ol>
                </div>
                <!-- Header Section -->

                <!-- Template Header Media Option -->
                <div
                  *ngIf="
                    selectedTemplate.content.header &&
                    selectedTemplate.content.header.header_type === 'media'
                  "
                  class="template_item"
                >
                  <h5>Header</h5>
                  <mat-divider class="my-2"></mat-divider>

                  <div class="upload_img" *ngIf="!file_urls.header">
                    <div *ngIf="!urlText">
                      <!-- Upload Template Image -->
                      <div
                        class="input-label label"
                        *ngIf="selectedTemplate.content.header.data[0].type === 'image'"
                      >
                        Upload Image <span class="text-danger" matTooltip="Image is mandatory">*</span>
                      </div>

                      <div
                        class="input-label label"
                        *ngIf="selectedTemplate.content.header.data[0].type === 'document'">
                        Upload Document
                        <span class="text-danger" matTooltip="Document is mandatory">*</span>
                      </div>

                      <div
                        class="input-label label"
                        *ngIf="selectedTemplate.content.header.data[0].type === 'video'"
                      >
                        Upload Video
                        <span class="text-danger" matTooltip="Video is mandatory">*</span>
                      </div>

                      <!-- Image Drag-n-Drop Area -->
                      <fieldset
                        class="media_upload_dropZone d-flex align-items-center justify-content-center gap-4 mb-3"
                      >
                        <legend class="visually-hidden">Image uploader</legend>
                        <!-- For Image Upload -->
                        <input
                          class="upload_image"
                          type="file"
                          *ngIf="
                            selectedTemplate.content.header &&
                            selectedTemplate.content.header.data[0].type ===
                              'image'
                          "
                          accept="image/jpeg, image/png"
                          (change)="
                            onSelect($event); $any($event.target).value = ''
                          "
                        />
                        <!-- END For Image Upload -->

                        <!-- For Video Upload -->
                        <input
                          class="upload_image"
                          type="file"
                          *ngIf="
                            selectedTemplate.content.header &&
                            selectedTemplate.content.header.data[0].type ===
                              'video'
                          "
                          accept="video/mp4, video/3gpp"
                          (change)="
                            onSelect($event); $any($event.target).value = ''
                          "
                        />
                        <!-- END For Video Upload -->

                        <!-- For Document Upload -->
                        <input
                          class="upload_image"
                          type="file"
                          *ngIf="
                            selectedTemplate.content.header &&
                            selectedTemplate.content.header.data[0].type ===
                              'document'
                          "
                          accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          (change)="
                            onSelect($event); $any($event.target).value = ''
                          "
                        />
                        <!-- END For Document Upload -->


                        <div class="d-flex align-items-center gap-3">
                          <div class="file_upload_icon">
                            <em class="bi bi-upload fs-1 text-muted"></em>
                          </div>
                          <div class="files_supported text-center">
                            <h4>
                              Drag &amp; Drop file or
                              <a class="text-primary">Browse</a>
                            </h4>

                            <!-- For Image -->
                            <p
                              class="small"
                              *ngIf="
                                selectedTemplate.content.header &&
                                selectedTemplate.content.header.data[0].type ===
                                  'image'
                              "
                            >
                              Supported <strong>.png, .jpeg</strong> format maximum file
                              size<strong>5MB</strong>
                            </p>
                            <!-- END For Image -->

                            <!-- For Document -->
                            <p
                              class="small"
                              *ngIf="
                                selectedTemplate.content.header &&
                                selectedTemplate.content.header.data[0].type ===
                                  'document'
                              "
                            >
                              Supported <strong>.pdf, .docx</strong> format maximum file
                              size <strong>100MB</strong>
                            </p>
                            <!-- END For Document -->

                            <!-- For Video -->
                            <p
                              class="small"
                              *ngIf="
                                selectedTemplate.content.header &&
                                selectedTemplate.content.header.data[0].type ===
                                  'video'
                              "
                            >
                              Supported<strong>.mp4, .3gpp</strong> format maximum file size
                              <strong>16MB</strong>
                            </p>
                            <!-- END For Video -->
                          </div>
                        </div>
                      </fieldset>
                      <!-- END Image Drag-n-Drop Area -->

                      <!-- Media Library Button -->
                      <button
                        class="btn btn-primary btn-xs mb-0"
                        type="button"
                        (click)="
                          openModal(mediaLibrary);
                          getMediaFiles(
                            selectedTemplate.content.header.data[0].type
                          )
                        "
                      >
                        Media Library
                      </button>
                      <!-- END Media Library Button -->

                      <div class="divider">
                        <mat-divider class="my-3"></mat-divider>
                        <span class="text-muted">OR</span>
                      </div>
                    </div>

                    <!-- Media Upload URL -->
                    <div class="form-group">
                      <label class="input-label" for="mediaURL">
                        From URL</label
                      >
                      <input
                        class="form-control"
                        id="mediaURL"
                        type="text"
                        placeholder="Paste URL here"
                        (paste)="checkInsertedUrl($event)"
                        (input)="checkInsertedUrl($event)"
                      />
                    </div>
                    <!-- ENd Media Upload URL -->
                  </div>

                  <!-- Uploaded Image -->
                  <div
                    class="upload_gallery my-2 flex-column align-items-start"
                    *ngIf="file_urls.header"
                  >
                    <div class="uploaded_image">
                      <!-- Uploaded Image -->
                      <img
                        [src]="file_urls.header.url"
                        [alt]="file_urls.header.file_name"
                        class="img-fluid"
                        *ngIf="
                          selectedTemplate.content.header.data[0].type ===
                          'image'
                        "
                      />
                      <!-- END Uploaded Image -->

                      <!-- Uploaded Document -->
                      <img
                        src="assets/img/customer/pdf.svg"
                        [alt]="file_urls.header.file_name"
                        class="img-fluid"
                        *ngIf="
                          selectedTemplate.content.header.data[0].type ===
                          'document'
                        "
                      />
                      <!-- END Uploaded Document -->

                      <!-- Uploaded Video -->
                      <img
                        src="assets/img/video_placeholder.svg"
                        class="img-fluid"
                        [alt]="file_urls.header.file_name"
                        *ngIf="
                          selectedTemplate.content.header.data[0].type ===
                          'video'
                        "
                      />
                      <!-- Uploaded Video -->

                      <!-- Option to clear uploaded media -->
                      <em
                        class="bi bi-x-circle-fill img_close"
                        (click)="file = null; file_urls.header = null; clearMediaData()"
                        (keydown)="file = null; file_urls.header = null; clearMediaData()"
                      >
                      </em>
                      <!-- END Option to clear uploaded media -->
                    </div>
                    <p>{{ file_urls.header.file_name }}</p>
                  </div>
                  <!-- END Uploaded Image -->
                </div>
                <!-- END Template Header Media Option -->

                <!-- Template Header Text Option -->
                <div
                  *ngIf="
                    selectedTemplate.content.header &&
                    selectedTemplate.content.header.header_type === 'text'
                  "
                  class="template_item"
                >
                  <h5>Header</h5>
                  <mat-divider class="my-2"></mat-divider>
                  <p>
                    {{ selectedTemplate.content.header.data[0].text }}
                  </p>

                  <!-- Variables -->
                  <div
                    class="form-group"
                    *ngIf="headervariablesarray.controls.length > 0"
                  >
                    <!-- Variable 1 -->
                    <ng-container formArrayName="headerVariables">
                      <div
                        class="row gap-2 my-2"
                        *ngFor="
                          let item of headervariablesarray.controls;
                          let i = index
                        "
                      >
                        <ng-container [formGroupName]="i">
                          <div class="col-auto pe-0">
                            <button
                              type="button"
                              class="btn btn-soft-dark h-100"
                              disabled
                            >
                              Variable &#123;&#123;<code>{{ i + 1 }}</code
                              >&#125;&#125;
                            </button>
                          </div>
                          <div class="col ps-1">
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter your personalized value."
                              formControlName="variable_header"
                              (input)="bodyVariablesChanged()"
                            />
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                    <!-- END Variable 1 -->
                  </div>
                  <!-- ENd Variables -->
                </div>
                <!-- END Template Header Text Option -->
                <!-- END Header Section -->

                <!-- Body Section -->
                <div class="template_item">
                  <h5>Body</h5>
                  <mat-divider class="my-2"></mat-divider>
                  <p
                    [innerHTML]="
                      templateservice.formatToHtml(
                        selectedTemplate.content.body.text
                      )
                    "
                  ></p>

                  <!-- Variables -->
                  <div
                    class="form-group"
                    *ngIf="bodyvariablesarray.controls.length > 0"
                  >
                    <ng-container formArrayName="bodyVariables">
                      <div
                        class="row gap-2 my-2"
                        *ngFor="
                          let item of bodyvariablesarray.controls;
                          let i = index
                        "
                      >
                        <ng-container [formGroupName]="i">
                          <div class="col-auto pe-0">
                            <button
                              type="button"
                              class="btn btn-soft-dark"
                              disabled
                            >
                              Variable &#123;&#123;<code>{{ i + 1 }}</code
                              >&#125;&#125;
                            </button>
                          </div>
                          <div class="col ps-1">
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter your personalized value."
                              formControlName="variable_body"
                              (input)="bodyVariablesChanged()"
                            />

                            <!-- Variables Text Input Error -->
                            <div
                              class="invalid-feedback"
                              *ngIf="bodyvariablesarray.controls.at(i).get('variable_body').touched || 
                                      bodyvariablesarray.controls.at(i).get('variable_body').dirty"
                            >
                            
                              <ng-container
                                *ngIf="
                                  bodyvariablesarray.controls.at(i).get('variable_body').value === '' ||
                                  bodyvariablesarray.controls.at(i).get('variable_body').value === null;
                                  else fcInvalid
                                "
                              >
                                Body vairiable input can not be empty.
                              </ng-container>
                              <ng-template #fcInvalid>
                                <span *ngIf="bodyvariablesarray.controls.at(i).get('variable_body').errors">
                                  Enter a valid personalized value.
                                </span>
                              </ng-template>
                            </div>
                            <!-- END Variables Text Input Error -->
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                  <!-- END Variables -->
                </div>
                <!-- END Body Section -->

                <!-- Footer Section -->
                <div
                  *ngIf="selectedTemplate.content.footer"
                  class="template_item"
                >
                  <h5>Footer</h5>
                  <mat-divider class="my-2"></mat-divider>
                  <p>{{ selectedTemplate.content.footer.text }}</p>
                </div>
                <!-- END Footer Section -->

                <!-- Buttons Section -->
                <div
                  *ngIf="
                    selectedTemplate.content.button_details &&
                    selectedTemplate.content.button_details.type ===
                      'call_to_action'
                  "
                  class="template_item"
                >
                  <h5>Buttons</h5>
                  <mat-divider class="mt-3 mb-1"></mat-divider>
                  <span class="label">Button Type:
                    <span class="text-dark">Call-To-Action</span>
                  </span>
                  <div
                    class="template_action_btns"
                    *ngIf="
                      selectedTemplate.content.button_details.buttons.length > 0
                    "
                  >
                    <ng-container
                      *ngFor="
                        let item of selectedTemplate.content.button_details
                          .buttons
                      "
                    >
                      <div
                        class="call_us_btn my-2"
                        *ngIf="item.type_of_action === 'phone_no'"
                      >
                        <button
                          type="button"
                          class="btn btn-outline-secondary w-20"
                        >
                          <em class="bi-telephone-fill"></em>
                          {{ item.button_text }}
                        </button>
                        <span class="ms-3">{{ item.phone_no }}</span>
                      </div>
                      <div
                        class="visit_us_btn my-2"
                        *ngIf="item.type_of_action === 'website'"
                      >
                        <button
                          type="button"
                          class="btn btn-outline-secondary w-20"
                        >
                          <em class="bi-box-arrow-up-right"></em>
                          {{ item.button_text }}
                        </button>
                        <span
                          class="ms-3 text-muted"
                          *ngIf="item.url_type === 'static'"
                          >{{ item.url }}</span
                        >
                        <span
                          class="ms-3 text-muted"
                          *ngIf="item.url_type === 'dynamic'"
                          >{{ item.url }}</span
                        >
                        <!-- Dynamic URL -->

                        <div
                          class="dynamic_url d-inline-flex ms-2"
                          *ngIf="item.url_type === 'dynamic'"
                        >
                          <ng-container formArrayName="dynamicBtnVariable">
                            <div
                              class="row"
                              *ngFor="
                                let t of dynamicvariablesarray.controls;
                                let i = index
                              "
                            >
                              <div [formGroupName]="i">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter URL Parameter {{ i + 1 }}"
                                  formControlName="variable_url"
                                />
                              </div>
                            </div>
                          </ng-container>
                          <!-- END Dynamic URL -->
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <!-- END Buttons Section -->
              </div>
              <!-- END Choosen Template Editing Options -->

              <mat-divider class="my-4"></mat-divider>

              <div class="form_action_btns">
                <div class="options_left">
                  <!-- Draft Button -->
                  <button
                    class="btn btn-soft-dark"
                    type="button"
                    (click)="saveToDraft()"
                    *ngIf="
                      campaignsetup.get('contacttype').value ===
                        'multiplecontacts' &&
                      campaignsetup.get('campaignName').value
                    "
                  >
                    <em class="bi-pencil-fill fs-6"></em>
                    Save Draft
                  </button>
                  <!-- END Draft Button -->

                  <!-- Cancel Button -->
                  <button
                    class="btn btn-outline-dark"
                    type="button"
                    (click)="cancelCampaignSetup(); file = null"
                  >
                    Cancel
                  </button>
                  <!-- END Cancel Button -->
                </div>
                <div class="options_right">
                  <!-- Step 1 Runnow Button -->
                  <!-- !validateVariables() -->
                  <button
                    class="btn btn-gradient"
                    type="button"
                    *ngIf="
                      campaignsetup.get('contacttype').value === 'singlecontact'
                    "
                    [disabled]="campaignNameExists ||
                      campaignsetup.invalid ||
                      campaignsetup.get('templateCategory').value ===
                        'Authentication'"
                    (click)="onCampaignTemplate()"
                  >
                    Run Now
                    <em class="bi-send-fill fs-6 rotate-45"></em>
                  </button>
                  <!-- End Step 1 Runnow Button -->

                  <!-- Step 1 Next Button -->
                  <button
                    class="btn btn-gradient"
                    type="button"
                    *ngIf="
                      campaignsetup.get('contacttype').value ===
                      'multiplecontacts'
                    "
                    [disabled]="campaignNameExists ||
                      campaignsetup.invalid ||
                      campaignsetup.get('templateCategory').value ===
                        'Authentication'"
                    (click)="file = null; checkData(); bodyVariablesChanged()"
                    matStepperNext
                  >
                    Next
                    <em class="bi-chevron-right fs-6"></em>
                  </button>
                  <!-- END Step 1 Next Button -->
                </div>
              </div>
            </form>
          </mat-step>
          <!-- END Step 1 Setup Campaign -->

          <!-- Step 2 Campaign CSV Upload & Validation -->
          <mat-step
            [stepControl]="campaignUploadCSV"
            *ngIf="
              campaignsetup.get('contacttype').value === 'multiplecontacts'
            "
          >
            <ng-template matStepLabel>
              <p class="text-sm fw-bold">Upload CSV</p>
            </ng-template>

            <form [formGroup]="campaignUploadCSV">
              <!-- Upload Multuple Contacts CSV -->
              <div class="upload_contacts">
                <h4>Upload list of target users</h4>
                <p>
                  Please ensure that your uploaded file includes mobile numbers
                  with the country code in the first column, followed by any
                  variables if applicable. To ensure that your file is formatted
                  correctly, you can download a sample file by
                  <span
                    class="text-primary text-decoration-underline pointer"
                    (click)="downloadSampleFile('text/csv;charset=utf-8')"
                    onkeydown=""
                    >Clicking Here.</span
                  >
                </p>
                <!-- CSV File Drag-n-Drop Area -->
                <fieldset
                  class="upload_dropZone media_upload_dropZone text-center mt-2 p-4"
                  *ngIf="!file_urls.bulkupload"
                >
                  <legend class="visually-hidden">CSV File uploader</legend>
                  <input
                    class="upload_image"
                    type="file"
                    accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    (change)="onSelect($event); $any($event.target).value = ''"
                  />
                  <div class="align-items-center text-center">
                    <div class="file_upload_icon">
                      <em class="bi bi-upload fs-1 text-muted"></em>
                    </div>
                    <div class="files_supported">
                      <h4 class="my-2">
                        Drag &amp; Drop file or
                        <a class="text-primary">Browse</a>
                      </h4>
                      <p>
                        Supported <strong>.CSV</strong> format maximum file size
                        <strong>2MB</strong>
                      </p>
                    </div>
                  </div>
                </fieldset>
                <!-- END CSV File Drag-n-Drop Area -->

                <!-- Uploaded Image -->
                <div
                  class="upload_gallery my-2 d-flex align-items-center justify-content-between"
                  *ngIf="file_urls.bulkupload"
                >
                  <div
                    class="uploaded_file_info d-flex align-items-center gap-2"
                  >
                    <div class="uploaded_image">
                      <img
                        src="assets/img/google-sheets.svg"
                        class="img-fluid"
                        alt="Nuacem"
                      />
                      <em
                        class="bi bi-x-circle-fill img_close"
                        (click)="onRemovingCsvFile()"
                        (keydown)="onRemovingCsvFile()"
                      ></em>
                    </div>
                    <span>{{ file_urls.bulkupload.file_name }}</span>
                  </div>

                  <button
                    class="btn btn-sm btn-primary"
                    [matTooltip]="showRefreshBtn ? 'Refresh now for latest analyzed data' : 'Refresh button will be enabled in ' + refresh_interval + 'sec.'"
                    (click)="showRefreshBtn ? refreshValidations() : ''"
                    [disabled]="!showRefreshBtn"
                  >
                    <em class="bi bi-arrow-repeat"></em> Refresh
                  </button>
                </div>
                <!-- END Uploaded Image -->
              </div>
              <!-- END Upload Multuple Contacts CSV -->

              <!-- Analyzing Loader -->
              <div class="csv_analyze mt-3" *ngIf="isAnalysing">
                <span class="analyze_loader w-100">Analyzing</span>
              </div>
              <!-- END Analyzing Loader -->

              <!-- Analyzed Data -->
              <div
                class="csv_analyzer_data mt-4 p-3"
                *ngIf="preprocessing_data && this.file_urls.bulkupload"
              >
                <div class="row row-cols-3 row-gap-3">
                  <div class="col">
                    <div class="card">
                      <label for="trcrds">Total Records</label>
                      <h1>{{ preprocessing_data.total }}</h1>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <label for="trcrds">Opt-In</label>
                      <h1>{{ preprocessing_data.optin }}</h1>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <label for="trcrds">Blocklist</label>
                      <h1>{{ preprocessing_data.blocked }}</h1>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <label for="trcrds">Non - Consent</label>
                      <h1>{{ preprocessing_data.nonconsent }}</h1>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <label for="trcrds">Opt-Out</label>
                      <h1>{{ preprocessing_data.optout }}</h1>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card">
                      <label for="trcrds">Invalid</label>
                      <h1>{{ preprocessing_data.invalid }}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END Analyzed Data -->

              <!-- Non-Consent Checkbox -->
              <div class="form-group mt-3" *ngIf="file_urls.bulkupload">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="nonConsent"
                    id="scheduleCamp"
                  />
                  <label class="form-check-label" for="scheduleCamp">
                    Send this campaign to non-consent recipients
                  </label>
                </div>
              </div>
              <!-- END Non-Consent Checkbox -->

              <!-- Schedule Campaign -->
              <div class="form-group mt-3" *ngIf="file_urls.bulkupload">
                <!-- Schedule Checkbox -->
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="scheduleCampaign"
                  />
                  <label class="form-check-label" for="scheduleCamp">
                    Schedule Campaign
                  </label>
                </div>
                <!-- END Schedule Checkbox -->

                <div
                  class="row row-cols-3 mt-2"
                  *ngIf="
                    campaignUploadCSV.get('scheduleCampaign').value === true
                  "
                >
                  <!-- Schedule Country Selection -->
                  <div class="col">
                    <label class="input-label" for="sch_country"
                      >Schedule Country
                    </label>
                    <ng-select
                      formControlName="scheduledcountry"
                      id="sch_country"
                      placeholder="Select Country"
                      bindLabel="name"
                      bindValue="iso2"
                      [items]="countries"
                      [clearable]="true"
                      [searchable]="true"
                      [closeOnSelect]="true"
                      dropdownPosition="bottom"
                    >
                      <ng-template ng-option-tmp let-item="item">
                        {{ item.name }}
                      </ng-template>
                      <ng-template ng-label-tmp let-item="item">
                        {{ item.name }}
                      </ng-template>
                    </ng-select>
                  </div>
                  <!-- END Schedule Country Selection -->

                  <!-- Scheduled Timezone Selection -->
                  <div
                    class="col"
                    *ngIf="campaignUploadCSV.get('scheduledcountry').value"
                  >
                    <label class="input-label" for="timeZone"
                      >Schedule Time Zone
                    </label>
                    <ng-select
                      formControlName="scheduledtimezone"
                      id="timeZone"
                      placeholder="Select Time Zone"
                      [items]="timeZones"
                      [clearable]="true"
                      [searchable]="true"
                      [closeOnSelect]="true"
                      dropdownPosition="bottom"
                    >
                    </ng-select>
                  </div>
                  <!-- END Scheduled Timezone Selection -->

                  <!-- Scheduled Date and Time -->
                  <div
                    class="col"
                    *ngIf="campaignUploadCSV.get('scheduledtimezone').value"
                  >
                    <label class="input-label" for="sDtTime"
                      >Schedul Date & Time
                    </label>
                    <div class="form-group">
                      <p-calendar
                        formControlName="scheduleDateTime"
                        [showTime]="true"
                        [showSeconds]="true"
                        [showIcon]="true"
                        [readonlyInput]="true"
                        [showButtonBar]="true"
                        [minDate]="minDate"
                        aria-label="nextHour"
                        aria-label="nextMinute"
                        aria-label="nextSecond"
                        placeholder="MM/DD/YYYY hh:mm:ss"
                      ></p-calendar>
                    </div>
                  </div>
                  <!-- END Scheduled Date and Time -->
                </div>
              </div>
              <!-- END Schedule Campaign -->

              <mat-divider class="my-4"></mat-divider>

              <div class="form_action_btns">
                <div class="options_left">
                  <!-- Step 2 Previous Button -->
                  <button
                    class="btn btn-gradient"
                    type="button"
                    *ngIf="
                      campaignsetup.get('contacttype').value ===
                      'multiplecontacts'
                    "
                    matStepperPrevious
                  >
                    <em class="bi-chevron-left fs-6"></em>
                    Previous
                  </button>
                  <!-- END Step 2 Previous Button -->

                  <!-- Draft Button -->
                  <button
                    class="btn btn-soft-dark"
                    type="button"
                    (click)="saveToDraft()"
                  >
                    <em class="bi-pencil-fill fs-6"></em>
                    Save Draft
                  </button>
                  <!-- END Draft Button -->

                  <!-- Cancel Button -->
                  <!-- <button
                    class="btn btn-outline-dark"
                    type="button"
                    tabindex="5"
                    (click)="
                      cancelCampaignSetup(); file = null
                    "
                  >
                    Cancel
                  </button> -->
                  <!-- END Cancel Button -->
                </div>
                <div class="options_right">
                  <!-- Step 2 Schedule Button -->
                  <button
                    class="btn btn-gradient"
                    type="button"
                    [disabled]="
                      campaignsetup.invalid ||
                      campaignsetup.get('templateCategory').value ===
                        'Authentication'
                    "
                    *ngIf="
                      campaignUploadCSV.get('scheduleCampaign').value === true
                    "
                    (click)="scheduleCampaign()"
                  >
                    Schedule Campaign
                    <em class="bi-send-fill fs-6 rotate-45"></em>
                  </button>
                  <!-- END Step 2 Schedule Button -->

                  <!-- Step 2 Run Now Button -->
                  <button
                    class="btn btn-gradient"
                    type="button"
                    [disabled]="
                      campaignsetup.invalid ||
                      campaignsetup.get('templateCategory').value === 'Authentication' || 
                      !file_urls.bulkupload
                    "
                    (click)="onCampaignTemplate()"
                    *ngIf="
                      campaignUploadCSV.get('scheduleCampaign').value ===
                        false && file_urls.bulkupload
                    "
                  >
                    Run Now
                    <em class="bi-send-fill fs-6 rotate-45"></em>
                  </button>
                  <!-- END Step 2 Next Button -->
                </div>
              </div>
            </form>
          </mat-step>
          <!-- END Step 2 Campaign CSV Upload & Validation -->

          <!-- Step 3 Success -->
          <mat-step>
            <ng-template matStepLabel>
              <p class="text-sm fw-bold">Success</p>
            </ng-template>

            <div class="text-center">
              <img
                src="assets/img/success.svg"
                alt="Campaign Initiated Successfully"
                class="success_img"
              />
              <!-- Campaign Initiated Instant -->
              <h3 class="my-2">{{ templateData }}</h3>
              <!-- END Campaign Initiated Instant -->

              <p class="sub_title">
                Campaign Report typically takes
                <strong class="text-dark"> 2 hours</strong> to update.
              </p>
              <!-- Campaigns button -->
              <button
                class="btn btn-gradient mt-2"
                tabindex="0"
                type="button"
                (click)="gotoListing()"
              >
                Go to Campaigns list
              </button>
              <!-- End Campaigns button -->
            </div>
          </mat-step>
          <!-- END Step 3 Success -->
        </mat-stepper>
      </div>
    </div>
    <!-- END Campaign Setup Form -->

    <!-- Campaign Setup Preview -->
    <div class="col-xl-4 col-sm-12 p-0">
      <div class="whatsapp_preview campaign_preview">
        <div class="box">
          <app-whatsapp-preview></app-whatsapp-preview>
        </div>
      </div>
    </div>
    <!-- END Campaign Setup Preview -->
  </div>
</div>

<!-- Media Library Modal -->
<ng-template #mediaLibrary let-c="close" let-d="dismiss">
  <div class="media_library">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Media Library</h4>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="d('Cross click')"
        ></button>
      </div>
      <div class="modal-body p-2 px-4">
        <div class="media_files">
          <!-- All Files Uploads Section -->
          <ng-container *ngIf="(mediaFiles$ | async)?.length > 0; else noimgs">
            <!-- Header Options -->
            <div class="row justify-content-between my-3">
              <div class="col-auto">
                <!-- Search by Media File Name -->
                <div class="input-group input-group-merge mb-0">
                  <div class="input-group-prepend input-group-text">
                    <em class="bi bi-search"></em>
                  </div>
                  <input
                    type="search"
                    class="form-control"
                    placeholder="Search By Name"
                    [(ngModel)]="searchData"
                    [ngClass]="searchData?.length < 3 && searchData?.length > 0 ? 'invalid-input': ''"
                  />
                </div>
                <!-- END Search by Media File Name -->
              </div>
            </div>
            <!-- END Header Options -->

            <mat-divider class="my-2"></mat-divider>

            <div
              class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4"
            >
              <div
                class="col mb-3 mb-lg-5"
                *ngFor="
                  let item of mediaFiles$ | async | datafilter : searchData
                "
              >
                <!-- Card -->
                <div
                  class="card card-sm card-hover-shadow card-header-borderless h-100 text-center p-2"
                  [ngClass]="
                    fileselection.isSelected(item) ? 'media_seleted' : ''
                  "
                >
                  <div class="card-header card-header-content-between">
                    <mat-checkbox
                      class="list-checkboxes m-0"
                      (click)="$event.stopPropagation()"
                      (keydown)="$event.stopPropagation()"
                      (change)="$event ? selectHandler(item) : null"
                      [checked]="fileselection.isSelected(item)"
                      [aria-label]="filecheckboxLabel(item)"
                    ></mat-checkbox>
                  </div>

                  <div class="card-body">
                    <div
                      [ngClass]="
                        item.file_type === 'image'
                          ? 'image_wrapper'
                          : 'media_avatar'
                      "
                    >
                      <!-- Image -->
                      <img
                        class="media_avatar_img"
                        [src]="
                          item.url ? item.url : 'assets/img/img_placeholder.svg'
                        "
                        [alt]="item.file_name"
                        *ngIf="item.file_type === 'image'"
                      />
                      <!-- END Image -->

                      <!-- PDF -->
                      <img
                        class="avatar"
                        src="assets/img/customer/pdf.svg"
                        [alt]="item.file_name"
                        *ngIf="item.mime_type === 'application/pdf'"
                      />
                      <!-- END PDF -->

                      <!-- Google sheets -->
                      <img
                        class="avatar"
                        src="assets/img/google-sheets.svg"
                        [alt]="item.file_name"
                        *ngIf="item.mime_type === 'text/csv'"
                      />
                      <!-- END Google sheets -->

                      <!-- Video -->
                      <img
                        class="avatar"
                        src="assets/img/video_placeholder.svg"
                        [alt]="item.file_name"
                        *ngIf="item.mime_type === 'video/mp4'"
                      />
                      <!-- END Video -->
                    </div>
                    <div class="media_title mt-3">
                      <h5 class="card-title">{{ item.file_name }}</h5>
                    </div>
                  </div>

                  <div class="card-footer">
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <p class="small">
                        <strong>Updated:</strong> {{ item.updated_at | date }}
                      </p>
                      <span class="small"
                        ><strong>File Size:</strong>
                        {{ item.size ? item.size : "N/A" }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- End Card -->
              </div>
              <!-- End Col -->
            </div>
            <!-- Pagination -->
            <div class="pagination-wrapper">
              <ngb-pagination
                class="ngb-pagination"
                [collectionSize]="totalmediafiles"
                [(page)]="page"
                [pageSize]="size"
                aria-label="pagination"
                (pageChange)="onPageEvent($event)"
                [rotate]="true"
                [maxSize]="3"
                [ellipses]="true"
                [boundaryLinks]="false"
              >
                <ng-template ngbPaginationPrevious>
                  <i class="bi-chevron-left"></i>
                  Previous</ng-template
                >
                <ng-template ngbPaginationNumber let-p>{{ p }}</ng-template>
                <ng-template ngbPaginationNext>
                  Next
                  <i class="bi-chevron-right"></i
                ></ng-template>
              </ngb-pagination>
            </div>
            <!-- END Pagination -->
          </ng-container>
          <!-- END All Images Uploads Section -->

          <!-- No Images Avaliable for the Media Format Choosen -->
          <ng-template #noimgs>
            <div class="align-items-center text-center mb-4">
              <img
                class="w-35"
                src="assets/img/no_data.svg"
                alt="No matching media found"
              />
              <p>No matching media found</p>
            </div>
          </ng-template>
          <!-- No Images Avaliable for the Media Format Choosen -->
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="d('Cross click')">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!fileselection.hasValue()"
          (click)="onFileSelect(fileselection.selected)"
        >
          Select
        </button>
      </div>
    </div>
  </div>
</ng-template>
<!-- END Media Library Modal -->

<!-- Campaing Setup/Scheduled Successfully -->
<ng-template #campaignSetupSucess let-data="data">
  <div class="card success_screen">
    <div class="text-center">
      <img
        src="assets/img/success.svg"
        alt="Campaign Created Successfully"
        class="success_img"
      />
      <!-- Campaign Created Instant -->
      <h3 class="my-2">{{ data }}</h3>
      <!-- END Campaign Created Instant -->

      <!-- Login button -->
      <button
        class="btn btn-lg btn-gradient"
        tabindex="0"
        type="button"
        (click)="gotoListing()"
      >
        Go to Campaigns list
      </button>
      <!-- End Login button -->
    </div>
  </div>
</ng-template>
<!-- End Campaing Setup/Scheduled Successfully -->
