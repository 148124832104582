import { RolesBasedPermissions } from 'src/app/appdata/roles.model';

export const rolePermissionsData: RolesBasedPermissions = {
  campagin_management: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  template_management: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  consent_management: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  settings: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  acl_management: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  profile_management: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  audit_logs: true,
  wallet: true,
  ocmpChannelManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  ocmpBspManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  adminAccountManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  adminLicenseManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  adminChannelManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  adminBspManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  adminAccountProfileManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  adminAccountAclManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  adminAccountCampaginManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  adminAccountTemplateManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  adminAccountSettings: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  adminAccountConsentManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  adminAccountWalletManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  partnerAccountManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  partnerLicenseManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  partnerChannelManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  partnerBspManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  partnerAccountProfileManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  partnerAccountAclManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  partnerAccountCampaginManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  partnerAccountTemplateManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  partnerAccountSettings: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  partnerAccountConsentManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  partnerAccountWalletManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  customerAccountManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  customerLicenseManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  customerChannelManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  customerBspManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  customerAccountProfileManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  customerAccountAclManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  customerAccountCampaginManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  customerAccountTemplateManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  customerAccountSettings: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  customerAccountConsentManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  customerAccountWalletManagement: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
};

export const customerPermissionsData = {
  control_centre: true,
  control_centre_dashboard_analytics: true,
  control_centre_bsp_analytics: true,
  acl_management: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  profile_management: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  campagin_management: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  template_management: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  consent_management: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  wallet: true,
  drive_management: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  settings: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  audit_logs: true,
  waba_business_account_management: {
    full_control: true,
    can_create: true,
    can_view: true,
    can_update: true,
    can_delete: true,
  },
  support: true,
};
