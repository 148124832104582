<!-- Loader -->
<div class="center">
  <span class="loader" *ngIf="loader.visibility | async"></span>
</div>
<div class="spinner-overlay" *ngIf="loader.visibility | async"></div>
<!-- END Loader -->

<!-- Add Template -->
<!-- Template Header -->
<div class="card p-3">
  <div class="top_header_options">
    <div class="d-flex align-items-center gap-2">
      <button class="btn btn-sm btn-icon btn-light" (click)="gotoListing()">
        <em class="bi bi-chevron-left"></em>
      </button>
      <h3 class="main_title">Create Template</h3>
    </div>

    <div class="temp_guide d-none">
      <span>Check the template guidelines before creating a template</span>
      <!-- Create Template Guidelines -->
      <button class="btn btn-secondary ml-2">
        <em class="bi-box-arrow-up-right me-2"></em> Template Guidelines
      </button>
      <!-- END Create Template Guidelines -->
    </div>
  </div>
</div>
<!-- END Template Header -->

<!-- template Body -->
<div class="card p-0 mt-2">
  <div class="row d-flex m-0 justify-content-between">
    <!-- Template Creation Form -->
    <div class="col-xl-8 col-sm-12">
      <div class="create_form_sec">
        <mat-stepper
          class="card_align_items"
          [linear]="!isLinear"
          #stepper
          (selectionChange)="stepChanged($event)"
        >
          <!-- Step 1 Setup Template -->
          <mat-step [stepControl]="templateCreationForm">
            <ng-template matStepLabel>
              <p class="mt-2 text-sm fw-bold">Setup</p>
            </ng-template>

            <form [formGroup]="templateCreationForm">
              <h3 class="mb-3">Setup Template</h3>

              <!-- Template Name -->
              <div class="form-group">
                <div class="d-flex align-items-center justify-content-between">
                  <label class="input-label mb-0" for="templateName"
                    >Template Name
                    <span
                      class="text-danger"
                      matTooltip="Template Name is mandatory"
                      >*</span
                    >
                  </label>
                  <p class="text-muted" *ngIf="charCount > 0">
                    {{ charCount }}/{{ maxCharCount }}
                  </p>
                </div>
                <p class="small mb-2">
                  Name can only be in lowercase alphanumeric characters and
                  underscores. <br />Special characters and white-space are not
                  allowed <br />e.g. - marketing_template_123
                </p>
                <input
                  class="form-control"
                  id="templateName"
                  type="text"
                  [maxlength]="maxCharCount"
                  placeholder="Enter here"
                  formControlName="tempName"
                  tabindex="0"
                />
                <!-- Template Name Error -->
                <div class="invalid-feedback">
                  <div
                    *ngIf="tcFC['tempName'].touched || tcFC['tempName'].dirty"
                  >
                    <ng-container
                      *ngIf="
                        tcFC['tempName'].value === '' ||
                          tcFC['tempName'].value === null;
                        else fcInvalid
                      "
                    >
                      Template name is required
                    </ng-container>
                    <ng-template #fcInvalid>
                      <span *ngIf="tcFC['tempName'].errors">
                        No Caps.Only alphanumeric and underscore are allowed.
                        Ex: abc_1abc
                      </span>
                    </ng-template>
                  </div>
                </div>
                <!-- END Template Name Error -->
              </div>
              <!-- ENd Template Name -->

              <!-- Template Language -->
              <div class="form-group">
                <label class="input-label mb-0" for="templatelang"
                  >Template Language
                  <span
                    class="text-danger"
                    matTooltip="Template Language is mandatory"
                    >*</span
                  >
                </label>
                <p class="small mb-2">
                  You will need to specify the language in which message
                  template is submitted.
                </p>
                <ng-select
                  id="templatelang"
                  formControlName="tempLanguage"
                  [items]="template_languages"
                  bindValue="langCode"
                  bindLabel="langName"
                  [multiple]="false"
                  [closeOnSelect]="true"
                  [clearable]="false"
                  [searchable]="true"
                  placeholder="-- Select --"
                  tabindex="0"
                >
                </ng-select>

                <!-- Template Language Error -->
                <div class="invalid-feedback">
                  <div
                    *ngIf="
                      tcFC['tempLanguage'].touched || tcFC['tempLanguage'].dirty
                    "
                  >
                    <ng-container
                      *ngIf="
                        tcFC['tempLanguage'].value === '' ||
                          tcFC['tempLanguage'].value === null;
                        else fcInvalid
                      "
                    >
                      Select atleast one template language
                    </ng-container>
                    <ng-template #fcInvalid>
                      <span *ngIf="tcFC['tempLanguage'].errors">
                        No Lang Selected
                      </span>
                    </ng-template>
                  </div>
                </div>
                <!-- END Template Language Error -->
              </div>
              <!-- END Template Language -->

              <!-- Template Category -->
              <div class="form-group">
                <div class="input-label label" for="templateCategory"
                  >Template Category
                  <span
                    class="text-danger"
                    matTooltip="Template Category is mandatory"
                    >*</span
                  >
                  <em
                    class="bi-info-circle ms-2"
                    matTooltip="Your template should fall under one of these categories."
                  ></em>
                </div>
                <p class="small mb-2">
                  Your template should fall under one of these categories.
                </p>
                <ng-select
                  id="templateCategory"
                  formControlName="tempCategory"
                  [items]="allCategories"
                  [multiple]="false"
                  [closeOnSelect]="true"
                  [clearable]="false"
                  [searchable]="false"
                  placeholder="-- Select --"
                  tabindex="0"
                >
                </ng-select>

                <!-- Auth Template Category Not-allowed in INDIA -->
                <div
                  class="card border-danger text-center my-2 shadow-none auth_not_allowed_msg"
                  *ngIf="
                    templateCreationForm.get('tempCategory').value ===
                    'Authentication'
                  "
                >
                  <h4>
                    Temporary Discontinuation of Authentication Messages in
                    India
                  </h4>
                  <p>
                    From 2 June, 2023  sending or creating Authentication
                    messages is temporarily unavailable for Indian users as per
                    Meta's guidelines. This functionality will not be available
                    to businesses based in India until later this year.
                  </p>
                </div>
                <!-- END Auth Template Category Not-allowed in INDIA -->

                <!-- Template Category Error -->
                <div class="invalid-feedback">
                  <div
                    *ngIf="
                      tcFC['tempCategory'].touched || tcFC['tempCategory'].dirty
                    "
                  >
                    <ng-container
                      *ngIf="
                        tcFC['tempCategory'].value === '' ||
                          tcFC['tempCategory'].value === null;
                        else fcInvalid
                      "
                    >
                      Select atleast one template category
                    </ng-container>
                    <ng-template #fcInvalid>
                      <span *ngIf="tcFC['tempCategory'].errors">
                        No Category Selected
                      </span>
                    </ng-template>
                  </div>
                </div>
                <!-- END Template Category Error -->
              </div>
              <!-- END Template Category -->

              <!-- Template Header Type -->
              <div
                class="form-group"
                *ngIf="
                  templateCreationForm.get('tempCategory').value !==
                  'Authentication'
                "
              >
                <div class="input-label label" for="templateType">
                  Template Header Type
                  <span
                    class="text-danger"
                    matTooltip="Template Header Type is mandatory"
                    >*</span
                  >
                  <em
                    class="bi-info-circle ms-2"
                    matTooltip="Should let the system know, whether template header type required or not"
                  ></em>
                </div>
                <ng-select
                  id="templateType"
                  formControlName="tempType"
                  [items]="templateTypes"
                  [multiple]="false"
                  [closeOnSelect]="true"
                  [clearable]="false"
                  [searchable]="false"
                  placeholder="-- Select --"
                  tabindex="0"
                  (change)="setTempTypeValue($event)"
                >
                </ng-select>

                <!-- Template Header Type Error -->
                <div class="invalid-feedback">
                  <div
                    *ngIf="
                      tcFC['tempType'].touched || tcFC['tempType'].dirty
                    "
                  >
                    <ng-container
                      *ngIf="
                        tcFC['tempType'].value === '' ||
                          tcFC['tempType'].value === null;
                        else fcInvalid
                      "
                    >
                      Select atleast one template header type
                    </ng-container>
                    <ng-template #fcInvalid>
                      <span *ngIf="tcFC['tempType'].errors">
                        No Template header type Selected
                      </span>
                    </ng-template>
                  </div>
                </div>
                <!-- END Template Header Type Error -->
              </div>
              <!-- END Template Type -->

              <mat-divider class="mt-5 mb-3"></mat-divider>

              <!-- Footer Action Buttons -->
              <div class="form_action_btns">
                <div class="options_left">
                  <!-- Cancel Button -->
                  <button
                    class="btn btn-outline-dark"
                    type="button"
                    tabindex="0"
                    (click)="cancelTemplateCreation()"
                  >
                    Cancel
                  </button>
                  <!-- END Cancel Button -->
                </div>

                <div class="options_right">
                  <!-- Step 1 Next Button -->
                  <button
                    class="btn btn-gradient"
                    type="button"
                    tabindex="0"
                    [disabled]="
                      templateCreationForm.invalid ||
                      templateCreationForm.get('tempCategory').value ===
                        'Authentication'
                    "
                    matStepperNext
                  >
                    Next
                    <em class="bi-chevron-right"></em>
                  </button>
                  <!-- END Step 1 Next Button -->
                </div>
              </div>
              <!-- END Footer Action Buttons -->
            </form>
          </mat-step>
          <!-- END Step 1 Setup Template -->

          <!-- Step 2 Content -->
          <mat-step [stepControl]="templateContentForm">
            <ng-template matStepLabel>
              <p class="mt-2 text-sm fw-bold">Content</p>
            </ng-template>

            <form [formGroup]="templateContentForm">
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <h3>
                  Content - {{ templateCreationForm.get("tempType")?.value }}
                </h3>
              </div>

              <!-- Template Header -->
              <div
                class="card border p-0 my-3"
                *ngIf="
                  templateCreationForm.get('tempType').value !== null &&
                  templateCreationForm.get('tempType').value !== 'None'
                "
              >
                <div class="card-header">
                  <div class="d-flex flex-column">
                    <h4 class="card-title">Header</h4>
                    <h6 class="card-subtitle fw-light m-0">
                      Add a title text or select media type from header
                    </h6>
                  </div>
                </div>

                <div class="header_content">
                  <ng-container
                    [ngSwitch]="templateCreationForm.get('tempType')?.value"
                  >
                    <div class="card-body">
                      <ng-container *ngSwitchCase="'Text'">
                        <div class="temp_input_text">
                          <input
                            type="text"
                            class="form-control"
                            [maxlength]="maxCharCount"
                            placeholder="Enter header text..."
                            formControlName="tempHeaderText"
                            (keyup)="varReplace($event)"
                            #headerinp
                          />
                          <span class="char_count text-muted">
                            {{ headerCharCount }}/{{ maxCharCount }}
                          </span>
                        </div>
                        <!-- Template Header Text Error -->
                        <div class="invalid-feedback">
                          <div
                            *ngIf="
                              tcontentFC['tempHeaderText'].touched ||
                              tcontentFC['tempHeaderText'].dirty
                            "
                          >
                            <ng-container
                              *ngIf="
                                tcontentFC['tempHeaderText'].value === '' ||
                                  tcontentFC['tempHeaderText'].value === null;
                                else fcInvalid
                              "
                            >
                              Header text is required
                            </ng-container>
                            <ng-template #fcInvalid>
                              <span *ngIf="tcontentFC['tempHeaderText'].errors">
                                Please enter a valid text format.
                              </span>
                            </ng-template>
                          </div>
                        </div>
                        <!-- END Template Header Text Error -->

                        <!-- Add Variables Button -->
                        <button
                          type="button"
                          class="btn btn-sm btn-outline-primary"
                          (click)="addHeaderVariables()"
                          *ngIf="
                            header_Var === null || header_Var?.length === 0
                          "
                        >
                          Add Variables <em class="bi-plus-lg"></em>
                        </button>
                        <!-- END Add Variables Button -->
                      </ng-container>
                      <ng-container *ngSwitchCase="'Media'">
                        <ng-select
                          [items]="allTypes"
                          [closeOnSelect]="true"
                          [multiple]="false"
                          [clearable]="false"
                          [searchable]="false"
                          placeholder="Select media type"
                          formControlName="tempHeaderMedia"
                        >
                        </ng-select>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
              <!-- END Template Header -->

              <!-- Template Body -->
              <div class="card border p-0 my-3">
                <div class="card-header">
                  <div class="d-flex flex-column">
                    <h4 class="card-title">Body</h4>
                    <h6 class="card-subtitle fw-light m-0">
                      Enter the text for your message in the language you
                      selected <br />
                      Upto 1024 characters are allowed.<br />
                      e.g. - Hello &#123;&#123;<code>1</code>&#125;&#125;, your
                      code will expire in &#123;&#123;<code>2</code>&#125;&#125;
                      mins.
                    </h6>
                  </div>
                </div>

                <div class="card-body template_body">
                  <p-editor
                    [style]="{ height: '200px' }"
                    (onInit)="editorOnInit($event)"
                    (onTextChange)="onInputChange()"
                    (onSelectionChange)="collapseEmoji()"
                    [ngClass]="bodyCharCount > 1024 ? 'invalid' : ''"
                  >
                    <ng-template pTemplate="header">
                      <span
                        class="ql-format-group d-flex align-items-center justify-content-between"
                      >
                        <div class="editor_options">
                          <!-- Editor Bold Option -->
                          <button
                            type="button"
                            class="ql-bold"
                            aria-label="Bold"
                            placement="bottom"
                            ngbTooltip="Bold"
                          ></button>
                          <!-- END Editor Bold Option -->

                          <!-- Editor Italic Option -->
                          <button
                            type="button"
                            class="ql-italic"
                            aria-label="Italic"
                            placement="bottom"
                            ngbTooltip="Add italic text"
                          ></button>
                          <!-- END Editor Italic Option -->

                          <!-- Editor Strikethrough Option -->
                          <button
                            type="button"
                            class="ql-strike"
                            aria-label="Strikethrough"
                            placement="bottom"
                            ngbTooltip="Strikethrough"
                          ></button>
                          <!-- END Editor Strikethrough Option -->

                          <!-- Editor Emoji Option -->
                          <button
                            type="button"
                            aria-label="Emoji"
                            placement="bottom"
                            ngbTooltip="Insert Emoji"
                            (click)="
                              isCollapsed = !isCollapsed;
                              stepChanged($event);
                              storeCourser()
                            "
                          >
                            <i class="bi bi-emoji-smile"></i>
                          </button>
                          <!-- END Editor Emoji Option -->
                        </div>

                        <span
                          class="txt_area_char_count text-muted"
                          *ngIf="bodyCharCount > 0"
                        >
                          {{ bodyCharCount }}/{{ maxBodyCharCount }}
                        </span>
                      </span>
                    </ng-template>
                  </p-editor>

                  <!-- Template Header Text Error -->
                  <div class="invalid-feedback">
                    <div
                      *ngIf="
                        tcontentFC['tempBody'].touched ||
                        tcontentFC['tempBody'].dirty
                      "
                    >
                      <ng-container
                        *ngIf="
                          tcontentFC['tempBody'].value === '' ||
                            tcontentFC['tempBody'].value === null;
                          else fcInvalid
                        "
                      >
                        Body text can not be empty.
                      </ng-container>
                      <ng-template #fcInvalid>
                        <span *ngIf="bodyCharCount > 1024">
                          Please check the character limit.
                        </span>
                      </ng-template>
                    </div>
                  </div>
                  <!-- END Template Header Text Error -->

                  <!-- Emoji Tray -->
                  <div
                    class="emoji-tray"
                    [ngClass]="isCollapsed ? 'visible' : ''"
                  >
                    <div
                      #collapse="ngbCollapse"
                      [ngbCollapse]="!isCollapsed"
                      class="card"
                    >
                      <div class="card-body">
                        <emoji-mart
                          (emojiClick)="addEmoji($event)"
                        ></emoji-mart>
                      </div>
                    </div>
                  </div>
                  <!-- End Emoji Tray -->
                  <!-- Add Variables Button -->
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-primary my-3"
                    (click)="onAddVariable()"
                  >
                    Add Variables <em class="bi-plus-lg"></em>
                  </button>
                  <!-- END Add Variables Button -->
                </div>
              </div>
              <!-- END Template Body -->

              <!-- Template Footer -->
              <div class="card border p-0 mb-3">
                <div
                  class="card-body"
                  [ngClass]="{
                    rounded: !templateContentForm.get('enabletempFooter')?.value
                  }"
                >
                  <div class="d-flex justify-content-between">
                    <div class="card-info">
                      <h4 class="card-title">Footer</h4>
                      <h6 class="card-subtitle fw-light m-0">
                        Upto 60 characters are only allowed in your message
                        content. .
                      </h6>
                    </div>
                    <mat-slide-toggle
                      class="slider pe-lg-0"
                      formControlName="enabletempFooter"
                    ></mat-slide-toggle>
                  </div>
                </div>

                <div
                  class="card-body"
                  *ngIf="templateContentForm.get('enabletempFooter')?.value"
                >
                  <div class="temp_input_text">
                    <input
                      type="text"
                      class="form-control"
                      [maxlength]="maxCharCount"
                      placeholder="Enter footer text..."
                      formControlName="footerText"
                    />
                    <span class="char_count text-muted">
                      {{ footerCharCount }}/{{ maxCharCount }}
                    </span>
                  </div>
                  <!-- Template Header Text Error -->
                  <div class="invalid-feedback">
                    <div
                      *ngIf="
                        tcontentFC['footerText'].touched ||
                        tcontentFC['footerText'].dirty
                      "
                    >
                      <ng-template>
                        <span *ngIf="tcontentFC['footerText'].errors">
                          Please enter a valid text format.
                        </span>
                      </ng-template>
                    </div>
                  </div>
                  <!-- END Template Header Text Error -->
                </div>
              </div>
              <!-- END Template Footer -->

              <!-- Template Buttons -->
              <div class="card border p-0 mb-3">
                <div
                  class="card-body"
                  [ngClass]="{
                    rounded:
                      !templateContentForm.get('enabletempButtons')?.value
                  }"
                >
                  <div class="d-flex justify-content-between">
                    <div class="card-info">
                      <h4 class="card-title">Buttons</h4>
                      <h6 class="card-subtitle fw-light m-0">
                        In addition to your message, you can send actions with
                        your message. <br />Maximum 25 characters are allowed in
                        CTA button title & Quick Replies.
                      </h6>
                    </div>
                    <mat-slide-toggle
                      class="slider pe-lg-0"
                      formControlName="enabletempButtons"
                    ></mat-slide-toggle>
                  </div>
                </div>
                <ng-container
                  *ngIf="templateContentForm.get('enabletempButtons')?.value"
                >
                  <div class="card-body">
                    <div class="row justify-content-between py-2">
                      <!-- Button Action Selection -->
                      <div class="col-4">
                        <ng-select
                          class="selection"
                          [items]="btnactions"
                          [closeOnSelect]="true"
                          [multiple]="false"
                          [clearable]="false"
                          [searchable]="false"
                          placeholder="Select Action"
                          (change)="ontemplateBtn($event)"
                          formControlName="buttontype"
                        >
                        </ng-select>
                      </div>
                      <!-- END Button Action Selection -->

                      <!-- Add Button More buttons -->
                      <div class="col text-end">
                        <button
                          type="button"
                          *ngIf="templateContentForm.get('buttontype')?.value"
                          class="btn btn-outline-primary text-nowrap"
                          (click)="
                            onAddButtons(
                              templateContentForm.get('buttontype')?.value
                            )
                          "
                        >
                          Add Button
                          <i class="bi-plus-lg"></i>
                        </button>
                      </div>
                      <!-- END Add Button More buttons -->
                    </div>

                    <!-- Call To Action -->
                    <div
                      class="border-top pt-3"
                      *ngIf="
                        templateContentForm.get('buttontype')?.value ===
                          'Call To Action' &&
                        callToActionFormArray.controls?.length > 0
                      "
                    >
                      <ng-container formArrayName="ctoaButtons">
                        <ng-container
                          *ngFor="
                            let button of callToActionFormArray.controls;
                            let i = index
                          "
                        >
                          <div [formGroupName]="i">
                            <div
                              class="d-flex justify-content-between align-items-center mb-1"
                            >
                              <h5>Button {{ i + 1 }}</h5>
                              <button
                                class="btn btn-sm bg-light"
                                type="button"
                                *ngIf="i !== 0"
                                (click)="onDeleteButton(i, 'calltoaction')"
                              >
                                <i class="bi-trash-fill text-danger"></i>
                              </button>
                            </div>
                            <div class="row gap-2 mx-0 mb-4">
                              <!-- Type of Action Selection -->
                              <div class="col p-0">
                                <ng-select
                                  class="selection"
                                  [items]="btnactionTypes"
                                  [closeOnSelect]="true"
                                  [clearable]="false"
                                  [searchable]="false"
                                  placeholder="Type of Action"
                                  formControlName="type"
                                  (change)="onbtnChange($event, i)"
                                >
                                </ng-select>
                              </div>
                              <!-- END Type of Action Selection -->

                              <!-- Button Name -->
                              <div class="col p-0">
                                <input
                                  class="form-control"
                                  type="text"
                                  id="text"
                                  placeholder="Enter Button Text"
                                  formControlName="text"
                                />

                                <!-- Contact Number Text Input Error -->
                                <div
                                  class="invalid-feedback"
                                  *ngIf="callToActionFormArray.controls.at(i).get('text').touched || 
                                         callToActionFormArray.controls.at(i).get('text').dirty"
                                >
                                
                                  <ng-container
                                    *ngIf="
                                      callToActionFormArray.controls.at(i).get('text').value === '' ||
                                      callToActionFormArray.controls.at(i).get('text').value === null;
                                      else fcInvalid
                                    "
                                  >
                                    Phone number button text can not be empty
                                  </ng-container>
                                  <ng-template #fcInvalid>
                                    <span *ngIf="callToActionFormArray.controls.at(i).get('text').errors">
                                      No unwanted space, numeric and special characters
                                      allowed.
                                    </span>
                                  </ng-template>
                                </div>
                                <!-- END Contact Number Text Input Error -->
                              </div>
                              <!-- Button Name -->

                              <!-- Phone Number Selected -->
                              <ng-container
                                *ngIf="
                                  button.get('type')?.value === 'Phone Number'
                                "
                              >
                                <div class="col p-0">
                                  <!-- Dial Code Select -->
                                  <ng-select
                                    formControlName="country"
                                    id="contactno"
                                    placeholder="Select Country"
                                    bindLabel="name"
                                    bindValue="dialCode"
                                    [items]="countries"
                                    [clearable]="true"
                                    [searchFn]="
                                      commonservice.customCountrySearchFn
                                    "
                                    [closeOnSelect]="true"
                                    dropdownPosition="bottom"
                                  >
                                    <ng-template ng-option-tmp let-item="item">
                                      +{{ item.dialCode }} {{ item.name }}
                                    </ng-template>
                                    <ng-template ng-label-tmp let-item="item">
                                      +{{ item.dialCode }} {{ item.name }}
                                    </ng-template>
                                  </ng-select>

                                  <!-- Contact Number Dial Code Error -->
                                  <div
                                    class="invalid-feedback"
                                    *ngIf="callToActionFormArray.controls.at(i).get('country').touched || 
                                          callToActionFormArray.controls.at(i).get('country').dirty"
                                  >
                                  
                                    <ng-container
                                      *ngIf="
                                        callToActionFormArray.controls.at(i).get('country').value === '' ||
                                        callToActionFormArray.controls.at(i).get('country').value === null;
                                        else fcInvalid
                                      "
                                    >
                                      Select a country dial code.
                                    </ng-container>
                                    <ng-template #fcInvalid>
                                      <span *ngIf="callToActionFormArray.controls.at(i).get('country').errors">
                                        Select a valid country dial code.
                                      </span>
                                    </ng-template>
                                  </div>
                                  <!-- END Contact Number Dial Code Error -->
                                  <!-- END Dial Code Select -->
                                </div>

                                <div class="col-12 p-0">
                                  <input
                                    type="tel"
                                    class="form-control"
                                    placeholder="Enter Phone Number"
                                    formControlName="phno"
                                    (keydown.arrowup)="(false)"
                                    (keydown.arrowdown)="(false)"
                                  />
                                </div>
                               
                                

                                <!-- Contact Number Error -->
                                <div
                                  class="invalid-feedback"
                                  *ngIf="callToActionFormArray.controls.at(i).get('phno').touched || callToActionFormArray.controls.at(i).get('phno').dirty"
                                >
                                
                                  <ng-container
                                    *ngIf="
                                      callToActionFormArray.controls.at(i).get('phno').value === '' ||
                                      callToActionFormArray.controls.at(i).get('phno').value === null;
                                      else fcInvalid
                                    "
                                  >
                                    Phone number can not be empty
                                  </ng-container>
                                  <ng-template #fcInvalid>
                                    <span *ngIf="callToActionFormArray.controls.at(i).get('phno').errors">
                                      Enter valid phone number between 10-16 numbers.
                                    </span>
                                  </ng-template>
                                </div>
                                <!-- END Contact Number Error -->

                              </ng-container>
                              <!-- END Phone Number Selected -->

                              <!-- Visit Website Selected -->
                              <ng-container
                                *ngIf="
                                  button.get('type')?.value === 'Visit Website'
                                "
                              >
                                <div class="col p-0">
                                  <ng-select
                                    class="selection"
                                    [items]="urlTypes"
                                    [closeOnSelect]="true"
                                    [clearable]="false"
                                    [searchable]="false"
                                    placeholder="URL Type"
                                    formControlName="urlType"
                                  >
                                  </ng-select>
                                </div>
                                <div
                                  class="col-12 p-0 d-flex align-items-center gap-1"
                                >
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Enter Website Url"
                                    formControlName="url"
                                  />
                                  <span
                                    class="w-auto text-nowrap"
                                    *ngIf="
                                      button.get('urlType')?.value === 'Dynamic'
                                    "
                                    >/
                                    &#123;&#123;<code>1</code>&#125;&#125;</span
                                  >
                                </div>
                              </ng-container>
                              <!-- END Visit Website Selected -->
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                    <!-- END Call To Action -->

                    <!-- Quick Replies -->
                    <div
                      class="border-top pt-3"
                      *ngIf="
                        templateContentForm.get('buttontype')?.value ===
                          'Quick Reply' &&
                        quickreplyFormArray.controls.length > 0
                      "
                    >
                      <div class="row gap-3 mx-0 mb-4">
                        <ng-container formArrayName="quickreplyBtns">
                          <ng-container
                            *ngFor="
                              let button of quickreplyFormArray.controls;
                              let i = index
                            "
                          >
                            <div [formGroupName]="i" class="col p-0">
                              <h5 class="text-nowrap">
                                Button Text {{ i + 1 }}
                              </h5>
                              <div class="position-relative">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Button Text"
                                  formControlName="quickreplyText"
                                />
                                <i
                                  class="bi bi-x remove_icon"
                                  (click)="onDeleteButton(i, 'quickreply')"
                                  (keydown)="onDeleteButton(i, 'quickreply')"
                                ></i>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                    <!-- END Quick Replies -->
                  </div>
                </ng-container>
              </div>
              <!-- END Template Buttons -->

              <mat-divider class="mt-4 mb-2"></mat-divider>

              <!-- Mat Stepper Action Buttons -->
              <div class="form_action_btns">
                <div class="options_left">
                  <!-- Draft Button -->
                  <button
                    class="btn btn-soft-dark"
                    type="button"
                    (click)="saveDraftTemplate()"
                  >
                    <em class="bi-pencil-fill"></em>
                    Save Draft
                  </button>
                  <!-- END Draft Button -->

                  <!-- Cancel Button -->
                  <button
                    class="btn btn-outline-dark"
                    type="button"
                    (click)="cancelTemplateCreation()"
                  >
                    Cancel
                  </button>
                  <!-- END Cancel Button -->
                </div>
                <div class="options_right">
                  <!-- Previous Button -->
                  <button
                    class="btn btn-outline-primary"
                    type="button"
                    matStepperPrevious
                    (click)="stepTwo()"
                  >
                    <em class="bi-chevron-left"></em>
                    Previous
                  </button>
                  <!-- END Previous Button -->

                  <!-- Mat Next Step Button -->
                  <button
                    class="btn btn-gradient"
                    type="button"
                    [disabled]="
                      templateContentForm.invalid || bodyCharCount > 1024
                    "
                    matStepperNext
                    (click)="addSampleData()"
                  >
                    Next
                    <em class="bi-chevron-right"></em>
                  </button>
                  <!-- END Mat Next Step Button -->
                </div>
              </div>
              <!-- END Mat Stepper Action Buttons -->
            </form>
          </mat-step>
          <!-- END Step 2 Content -->

          <!-- Step 3 Sample Content -->
          <mat-step [stepControl]="addSampleForm">
            <ng-template matStepLabel>
              <p class="mt-2 text-sm fw-bold">Add Sample Content</p>
            </ng-template>

            <form [formGroup]="addSampleForm">
              <div
                class="form-group"
                *ngIf="
                  addSampleForm.get('headerTemplateType')?.value === 'Media'
                "
              >
                <label for="fileupload">Header (Choose File)</label>
                <!-- Upload Media -->
                <div class="upload_sample_media">
                  <!-- Image Drag-n-Drop Input -->
                  <fieldset
                    class="upload_dropZone media_upload_dropZone text-center mt-2 p-4"
                    *ngIf="!file"
                  >
                    <legend class="visually-hidden">Media File uploader</legend>
                    <input
                      class="upload_image"
                      type="file"
                      *ngIf="
                        addSampleForm
                          .get('headerMediaType')
                          ?.value.toLowerCase() === 'image'
                      "
                      accept="image/jpeg, image/png"
                      (change)="onSelect($event)"
                    />
                    <input
                      class="upload_image"
                      type="file"
                      *ngIf="
                        addSampleForm
                          .get('headerMediaType')
                          ?.value.toLowerCase() === 'video'
                      "
                      accept="video/mp4, video/3gpp"
                      (change)="onSelect($event)"
                    />
                    <input
                      class="upload_image"
                      type="file"
                      *ngIf="
                        addSampleForm
                          .get('headerMediaType')
                          ?.value.toLowerCase() === 'document'
                      "
                      accept="application/pdf"
                      (change)="onSelect($event)"
                    />
                    <div class="align-items-center text-center">
                      <div class="file_upload_icon">
                        <em class="bi bi-upload fs-1 text-muted"></em>
                      </div>
                      <div class="files_supported">
                        <h4 class="my-2">
                          Drag and Drop file or
                          <a class="text-primary">Browse</a>
                        </h4>
                        <p
                          *ngIf="
                            addSampleForm
                              .get('headerMediaType')
                              ?.value.toLowerCase() === 'image'
                          "
                        >
                          Supported
                          <b>.png, .jpeg</b> format maximum file size
                          <b>5MB</b>
                        </p>
                        <p
                          *ngIf="
                            addSampleForm
                              .get('headerMediaType')
                              ?.value.toLowerCase() === 'video'
                          "
                        >
                          Supported
                          <b>.mp4, .3gpp</b>
                          format maximum file size <b>16MB</b>
                        </p>
                        <p
                          *ngIf="
                            addSampleForm
                              .get('headerMediaType')
                              ?.value.toLowerCase() === 'document'
                          "
                        >
                          Supported
                          <b>.pdf, .docx</b> format maximum file size
                          <b>100MB</b>
                        </p>
                      </div>
                    </div>
                  </fieldset>
                  <!-- END Image Drag-n-Drop Input -->

                  <!-- Uploaded Image -->
                  <div class="upload_gallery my-2" *ngIf="file">
                    <div
                      class="uploaded_image me-2"
                      *ngIf="
                        addSampleForm
                          .get('headerMediaType')
                          ?.value.toLowerCase() === 'image'
                      "
                    >
                      <img
                        src="assets/img/customer/placeholder-img-format.svg"
                        class="img-fluid"
                        [alt]="file.name"
                      />
                      <em
                        class="bi bi-x-circle-fill img_close"
                        (click)="
                          file = null;
                          addSampleForm.get('uploadMedia').setValue(null)
                        "
                        (keydown)="
                          file = null;
                          addSampleForm.get('uploadMedia').setValue(null)
                        "
                      ></em>
                    </div>
                    <div
                      class="uploaded_image"
                      *ngIf="
                        addSampleForm
                          .get('headerMediaType')
                          ?.value.toLowerCase() === 'video'
                      "
                    >
                      <img
                        src="assets/img/video_placeholder.svg"
                        class="img-fluid"
                        [alt]="file.name"
                      />
                      <em
                        class="bi bi-x-circle-fill img_close"
                        (click)="
                          file = null;
                          addSampleForm.get('uploadMedia').setValue(null)
                        "
                        (keydown)="
                          file = null;
                          addSampleForm.get('uploadMedia').setValue(null)
                        "
                      ></em>
                    </div>
                    <div
                      class="uploaded_image"
                      *ngIf="
                        addSampleForm
                          .get('headerMediaType')
                          ?.value.toLowerCase() === 'document'
                      "
                    >
                      <img
                        src="assets/img/customer/pdf.svg"
                        class="img-fluid"
                        [alt]="file.name"
                      />
                      <em
                        class="bi bi-x-circle-fill img_close"
                        (click)="
                          file = null;
                          addSampleForm.get('uploadMedia').setValue(null)
                        "
                        (keydown)="
                          file = null;
                          addSampleForm.get('uploadMedia').setValue(null)
                        "
                      ></em>
                    </div>
                    <span>{{ file.name || file}}</span>
                  </div>
                  <!-- END Uploaded Image -->
                </div>
                <!-- END Upload Media -->
              </div>

              <div
                class="form-group"
                *ngIf="
                  addSampleForm.get('headerTemplateType')?.value === 'Text'
                "
              >
                <!-- Sample Header Text -->
                <label for="headertext">Header</label>
                <p
                  id="headertext"
                  [innerHTML]="addSampleForm.get('headertext')?.value"
                ></p>
                <!-- END Sample Header Text -->
                <!-- Header Assigned Variables -->
                <div *ngIf="headerVariableArray.controls.length > 0">
                  <ng-container formArrayName="headerVariables">
                    <ng-container
                      *ngFor="
                        let button of headerVariableArray.controls;
                        let i = index
                      "
                    >
                      <div [formGroupName]="i" class="row gap-2 my-2">
                        <div class="col-auto pe-0">
                          <button
                            type="button"
                            class="btn btn-soft-dark h-100"
                            disabled
                          >
                            {{ button.value.variable }}
                          </button>
                        </div>
                        <div class="col ps-1 var_input">
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter sample variable name"
                            formControlName="variableName"
                          />
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
                <!-- END Header Assigned Variables -->
              </div>

              <div
                class="form-group"
                *ngIf="addSampleForm.get('bodyText')?.value"
              >
                <!-- Sample Body Text -->
                <label for="body">Body</label>
                <p
                  id="body"
                  [innerHTML]="shareservice.quillInstance.root.innerHTML"
                ></p>
                <!-- End Sample Body Text -->

                <!-- Sample content for Body Assigned variables in the body -->
                <div *ngIf="bodyVariableArray.controls?.length > 0">
                  <ng-container formArrayName="bodyVariables">
                    <ng-container
                      *ngFor="
                        let button of bodyVariableArray.controls;
                        let i = index
                      "
                    >
                      <div [formGroupName]="i" class="row gap-2 my-2">
                        <div class="col-auto pe-0">
                          <button
                            type="button"
                            class="btn btn-soft-dark h-100"
                            disabled
                          >
                            {{ button.value.variable }}
                          </button>
                        </div>
                        <div class="col ps-1 var_input">
                          <input
                            class="form-control"
                            type="text"
                            formControlName="variableName"
                            placeholder="Enter sample variable name"
                          />
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
                <!-- END Sample content for Body Assigned variables in the body -->
              </div>

              <div
                class="form-group"
                *ngIf="addSampleForm.get('footerText')?.value"
              >
                <!-- Sample Footer Text -->
                <label for="footer">Footer</label>
                <p>{{ addSampleForm.get("footerText")?.value }}</p>
                <!-- END Sample Footer Text -->
              </div>
              <!-- Sample Buttons -->
              <ng-container *ngIf="addsamplectarray.controls?.length > 0">
                <ng-container formArrayName="ctoaButtons">
                  <label for="buttons">Buttons</label>
                  <div class="card bg-light border p-3" id="buttons">
                    <ng-container
                      *ngFor="
                        let item of addsamplectarray.controls;
                        let i = index
                      "
                    >
                      <div [formGroupName]="i">
                        <div class="row row-cols-2 align-items-center my-1">
                          <div class="col-4">
                            <button
                              class="btn btn-outline-secondary text-nowrap btn-block"
                            >
                              <i
                                class="bi-box-arrow-up-right me-1"
                                *ngIf="item.value.type === 'Visit Website'"
                              ></i>
                              <i
                                class="bi-telephone-fill me-1"
                                *ngIf="item.value.type === 'Phone Number'"
                              ></i>
                              {{ item.value.text }}
                            </button>
                          </div>
                          <div class="col">
                            <ng-container
                              *ngIf="item.value.type === 'Phone Number'"
                            >
                              {{ item.value.phno }}
                            </ng-container>
                            <ng-container
                              *ngIf="
                                item.value.type === 'Visit Website' &&
                                item.value.urlType === 'Static'
                              "
                            >
                              {{ item.value.url }}
                            </ng-container>
                            <ng-container
                              *ngIf="
                                item.value.type === 'Visit Website' &&
                                item.value.urlType === 'Dynamic'
                              "
                            >
                              <input
                                class="form-control"
                                type="text"
                                formControlName="urlvariable"
                                placeholder="Enter full url for {{
                                  item.value.url
                                }}"
                              />
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container
                *ngIf="addsamplequickreplyarray.controls?.length > 0"
              >
                <ng-container formArrayName="quickreplyBtns">
                  <label for="buttons">Buttons</label>
                  <div class="card bg-light border p-3" id="buttons">
                    <div class="row row-cols-3">
                      <ng-container
                        *ngFor="
                          let item of addsamplequickreplyarray.controls;
                          let i = index
                        "
                      >
                        <div [formGroupName]="i">
                          <div class="col">
                            <button
                              class="btn btn-outline-secondary text-nowrap btn-block"
                            >
                              {{ item.value.quickreplyText }}
                            </button>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <mat-divider class="mt-5 mb-3"></mat-divider>

              <!-- Mat Stepper Action Buttons -->
              <div class="form_action_btns">
                <div class="options_left">
                  <!-- Draft Button -->
                  <button
                    class="btn btn-soft-dark"
                    type="button"
                    (click)="saveDraftTemplate()"
                  >
                    <em class="bi-pencil-fill"></em>
                    Save Draft
                  </button>
                  <!-- END Draft Button -->

                  <!-- Cancel Button -->
                  <button
                    class="btn btn-outline-dark"
                    type="button"
                    (click)="stepper.reset()"
                  >
                    Cancel
                  </button>
                  <!-- END Cancel Button -->
                </div>
                <div class="options_right">
                  <!-- Previous Button -->
                  <button
                    class="btn btn-outline-primary"
                    type="button"
                    matStepperPrevious
                    (click)="clearAddSampleData()"
                  >
                    <em class="bi-chevron-left"></em>
                    Previous
                  </button>
                  <!-- END Previous Button -->

                  <!-- Mat Next Step Button -->
                  <button
                    class="btn btn-gradient"
                    type="button"
                    [disabled]="addSampleForm.invalid"
                    (click)="saveSampleData()"
                    matStepperNext
                  >
                    Next
                    <em class="bi-chevron-right"></em>
                  </button>
                  <!-- END Mat Next Step Button -->
                </div>
              </div>
              <!-- END Mat Stepper Action Buttons -->
            </form>
          </mat-step>
          <!-- END Step 3 Sample Content -->

          <!-- Step 4 Confirmation -->
          <mat-step>
            <ng-template matStepLabel>
              <p class="mt-2 text-sm fw-bold">Confirmation</p>
            </ng-template>

            <h3 class="mb-3">Confirmation</h3>
            <div class="confirm_details">
              <!-- Header -->
              <div class="row flex-column flex-xl-row">
                <label
                  class="col col-xl-4 text-xl-end text-nowrap"
                  for="header"
                >
                  Header :
                </label>
                <span class="col col-xl-8">
                  {{ templateCreationForm.get("tempType")?.value }}
                </span>
              </div>
              <!-- END Header -->

              <!-- Body -->
              <div class="row flex-column flex-xl-row">
                <label class="col col-xl-4 text-xl-end" for="body"
                  >Body :</label
                >
                <span
                  class="col-8"
                  [innerHTML]="
                    templateContentForm.get('tempBody')?.value
                      ? templateservice.formatToHtml(
                          templateContentForm.get('tempBody')?.value
                        )
                      : null
                  "
                ></span>
              </div>
              <!-- END Body -->

              <!-- Footer -->
              <div class="row flex-column flex-xl-row">
                <label class="col col-xl-4 text-xl-end" for="footer"
                  >Footer :</label
                >
                <span class="col col-xl-8">{{
                  templateContentForm.get("footerText")?.value
                }}</span>
              </div>
              <!-- END Footer -->

              <!-- Buttons -->
              <div
                class="row flex-column flex-xl-row align-items-center"
                *ngIf="
                  templateContentForm.get('ctoaButtons').value.length > 0 ||
                  templateContentForm.get('quickreplyBtns').value.length > 0
                "
              >
                <label class="col col-xl-4 text-xl-end" for="buttons"
                  >Buttons :</label
                >
                <div class="col col-xl-8">
                  <!-- Call-to-action Buttons -->
                  <ng-container
                    *ngIf="
                      templateContentForm.get('ctoaButtons').value.length > 0
                    "
                  >
                    <ng-container
                      *ngFor="
                        let item of templateContentForm.get('ctoaButtons').value
                      "
                    >
                      <button
                        class="btn btn-outline-secondary text-nowrap mx-2"
                      >
                        {{ item.text }}
                      </button>
                    </ng-container>
                  </ng-container>
                  <!-- END Call-to-action Buttons -->

                  <!-- Quick Reply Buttons -->
                  <ng-container
                    *ngIf="
                      templateContentForm.get('quickreplyBtns').value.length > 0
                    "
                  >
                    <div class="row gap-2 gap-lg-3 mx-0">
                      <ng-container
                        *ngFor="
                          let item of templateContentForm.get('quickreplyBtns')
                            .value
                        "
                      >
                        <div class="col p-0">
                          <button class="btn btn-outline-secondary text-nowrap">
                            {{ item.quickreplyText }}
                          </button>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                  <!-- END Quick Reply Buttons -->
                </div>
              </div>
              <!-- END Buttons -->
            </div>

            <mat-divider class="my-4"></mat-divider>

            <!-- Mat Stepper Action Buttons -->
            <div class="form_action_btns">
              <div class="options_left">
                <!-- Draft Button -->
                <button
                  class="btn btn-soft-dark"
                  type="button"
                  (click)="saveDraftTemplate()"
                >
                  <em class="bi-pencil-fill"></em>
                  Save Draft
                </button>
                <!-- END Draft Button -->

                <!-- Cancel Button -->
                <button
                  class="btn btn-outline-dark"
                  type="button"
                  (click)="stepper.reset()"
                >
                  Cancel
                </button>
                <!-- END Cancel Button -->
              </div>
              <div class="options_right">
                <!-- Previous Button -->
                <button
                  class="btn btn-outline-primary"
                  type="button"
                  matStepperPrevious
                >
                  <em class="bi-chevron-left"></em>
                  Previous
                </button>
                <!-- END Previous Button -->

                <!-- Send for Approval Button -->
                <button
                  class="btn btn-gradient"
                  type="button"
                  [disabled]="
                    templateContentForm.get('tempHeader')?.invalid ||
                    templateContentForm.get('tempBody')?.invalid
                  "
                  (click)="submitTemplate()"
                >
                  Send For Approval
                </button>
                <!-- END Send for Approval Button -->
              </div>
            </div>
            <!-- END Mat Stepper Action Buttons -->
          </mat-step>
          <!-- END Step 4 Confirmation -->

          <!-- Step 5 Success -->
          <mat-step>
            <ng-template matStepLabel>
              <p class="mt-2 text-sm fw-bold">Success</p>
            </ng-template>

            <div class="text-center">
              <img src="assets/img/success.png" class="w-20" alt="Success" />
              <h4 class="my-2">Successfully submitted</h4>
              <p class="sub_title">
                your template as been sent to meta for approval it will take
                maximum
                <strong class="text-dark"> 24 hours</strong> for approval
              </p>

              <!-- Goto Template List Button -->
              <button
                type="button"
                class="btn btn-gradient mt-2"
                (click)="goToTemplateList()"
              >
                Go to Template List
              </button>
              <!-- END Goto Template List Button -->
            </div>
          </mat-step>
          <!-- END Step 5 Success -->
        </mat-stepper>
      </div>
    </div>
    <!-- END Template Creation Form -->

    <!-- Template Creation Preview -->
    <div class="col-xl-4 col-sm-12 p-0">
      <div class="whatsapp_preview template_preview">
        <div class="box" *ngIf="stepper.selectedIndex !== 2">
          <app-whatsapp-preview></app-whatsapp-preview>
        </div>
        <div class="box" *ngIf="stepper.selectedIndex === 2">
          <app-whatsapp-preview></app-whatsapp-preview>
        </div>
      </div>
    </div>
    <!-- END Template Creation Preview -->
  </div>
</div>
<!-- END template B0dy -->
<!-- END Add Template -->
