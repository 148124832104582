<app-header></app-header>

<!-- Main Content Container -->
<main role="main" class="main h-100 m-0" id="channelInfo">
  <div class="container w-80 sec_gap_20">
    <!-- PAGE HEADER SECTION -->
    <section class="page-header-sec">
      <div class="row align-items-center justify-content-between">
        <div class="col">
          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" (click)="gotoChannels()">
                <a>All Channels</a>
              </li>
              <em class="bi bi-chevron-right"></em>
              <li class="breadcrumb-item active" aria-current="page">
                <h5>{{channel_name}}</h5>
              </li>
            </ol>
          </nav>
          <!-- END Breadcrumb -->
        </div>
      </div>
    </section>
    <!-- END PAGE HEADER SECTION -->
    <mat-divider></mat-divider>
    
    <ng-containter [ngTemplateOutlet]="displayTemplate()"></ng-containter>
  </div>
</main>
<!-- END Main Content Container -->

<ng-template #email>
  <div class="channel_sec sec_gap_20">
    <div class="welcome_user">
      <h1>Powerful Email APIs for Seamless Integration</h1>
      <p>Access comprehensive documentation and interactive Swagger UI to supercharge your email workflows.</p>
    </div>

    <div class="features_list sec_gap_20">
      <div class="info-box">
        <div class="info_icon">
          <em class="bi bi-chevron-double-right"></em>
        </div>
        <div class="info_desc">
          <h5>Ease of Integration</h5>
          <p class="small">Simple and developer-friendly APIs for quick implementation.</p>
        </div>
      </div>

      <div class="info-box">
        <div class="info_icon">
          <em class="bi bi-chevron-double-right"></em>
        </div>
        <div class="info_desc">
          <h5>Robust Functionality</h5>
          <p class="small">Feature-rich API endpoints to cover all your email needs.</p>
        </div>
      </div>

      <div class="info-box">
        <div class="info_icon">
          <em class="bi bi-chevron-double-right"></em>
        </div>
        <div class="info_desc">
          <h5>Secure & Reliable</h5>
          <p class="small">Built with security and reliability in mind, ensuring smooth email operations.</p>
        </div>
      </div>
    </div>
  
    <div class="row row-cols-md-2 g-4">
      <div class="col">
        <!-- API Documentation -->
        <div class="card card-hover-shadow p-4">
          <!-- Body -->
          <div class="card-body p-0">
            <div class="details">
              <!-- Channel Icon -->
              <div class="d-flex justify-content-between mb-2">
                <div class="avatar avatar-circle avatar-lg">
                  <img
                    class="avatar-img"
                    src="/assets/img/api.svg"
                    alt="API icon"
                  />
                </div>
              </div>
              <!-- END Channel Icon -->
              <h4 class="mb-2">
                API Documentation
              </h4>
              <p>Our documentation provides detailed guides, example requests, and response formats to help you integrate with our Email APIs effortlessly.</p>
            </div>
          </div>
          <!-- End Body -->
          <div class="dropdown-divider"></div>
          <!-- Footer -->
          <div class="card-footer p-0">
            <button class="btn btn-secondary">
              View API Documentation
            </button>
          </div>
          <!-- End Footer -->
        </div>
        <!-- End API Documentation -->
      </div>

      <div class="col">
        <!-- Swagger UI -->
        <div class="card card-hover-shadow p-4">
          <!-- Body -->
          <div class="card-body p-0">
            <div class="details">
              <!-- Channel Icon -->
              <div class="d-flex justify-content-between mb-2">
                <div class="avatar avatar-circle avatar-lg">
                  <img
                    class="avatar-img"
                    src="/assets/img/swagger.svg"
                    alt="Swagger icon"
                  />
                </div>
              </div>
              <!-- END Channel Icon -->
              <h4 class="mb-2">
                Swagger UI
              </h4>
              <p>Utilize our interactive Swagger UI to explore and test API endpoints in real-time. Understand request-response cycles with live data.</p>
            </div>
          </div>
          <!-- End Body -->
          <div class="dropdown-divider"></div>
          <!-- Footer -->
          <div class="card-footer p-0">
            <button class="btn btn-secondary">
              Explore Swagger UI
            </button>
          </div>
          <!-- End Footer -->
        </div>
        <!-- End Swagger UI -->
      </div>
    </div>
  </div>
</ng-template>