import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { selectCommonDataDtls } from '../store/common.selectors';
import { AlertService } from '../alert/alert.service';
import { WhitelabelingData } from '../appdata/initialwlb.data';
import { HttpClient } from '@angular/common/http';

declare let FB: any;

@Component({
  selector: 'app-fb-embedded',
  templateUrl: './fb-embedded.component.html',
  styleUrls: ['./fb-embedded.component.scss']
})
export class FbEmbeddedComponent implements OnInit, AfterViewInit, OnDestroy{

  showLoginLoader: boolean = false;
  whiteLabeledData: any = WhitelabelingData;
  displayTemplate!: TemplateRef<any>;
  destroy$: Subject<boolean> = new Subject<boolean>();

  data:any = {
    "data":{
      "phone_number_id":"349676381573159",
      "waba_id": "433221849867312"
    },
    "type":"WA_EMBEDDED_SIGNUP",
    "event":"FINISH"
  };

  @ViewChild('showLogin') private showLogin!: TemplateRef<any>;

  constructor(
    private cd: ChangeDetectorRef,
    private alertMsg: AlertService,
    private readonly store: Store,
    private http: HttpClient
  ) {}
  ngOnInit(): void {
    this.subscribeToWblStateData();
    this.initFacebookSDK();
  }

  subscribeToWblStateData() {
    this.store
      .select(selectCommonDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.wlbres) {
          this.whiteLabeledData = res.wlbres.wlb_settings
            ? res.wlbres.wlb_settings
            : res.wlbres;
          if (typeof this.whiteLabeledData === 'string') {
            this.whiteLabeledData = JSON.parse(this.whiteLabeledData);
          }
        } else if (res.error) {
          const dispErr = res.error.error.message;
          this.handleLoginError(dispErr);
        }
      });
  }

  handleLoginError(msg: string) {
    this.alertMsg.alertDanger('Error', msg);
  }

  ngAfterViewInit() {
    this.displayTemplate = this.showLogin;
    this.cd.detectChanges();
  }

  fbSignUp()
  {
    this.launchWhatsAppSignup();
    window.addEventListener('message', (e) => {
      this.sessionInfoListener(e);
    });
  }

  initFacebookSDK() {
    (window as any).fbAsyncInit = () => {
      FB.init({
        appId: '851978463043361', // Facebook App ID
        cookie: true,
        xfbml: true,
        version: 'v18.0' // Graph API version
      });
    };
    (function(d, s, id){
      let js:any = d.getElementsByTagName(s)[0];
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  // Facebook Login with JavaScript SDK
	launchWhatsAppSignup() {
    // Conversion tracking code
    // fbq && fbq('trackCustom', 'WhatsAppOnboardingStart', {appId: 'your-facebook-app-id', feature: 'whatsapp_embedded_signup'});

    // Launch Facebook login
		FB.login((response:any) => {
			if (response.authResponse) {
				// sessionInfoListener();
				const accessToken = response.authResponse.accessToken;
        // The returned code must be transmitted to your backend, 
        // which will perform a server-to-server call from there to our servers for an access token
        //Use this token to call the debug_token API and get the shared WABA's ID
			} else {
				console.log('User cancelled login or did not fully authorize.');
			}
		}, {
			scope: 'whatsapp_business_management, whatsapp_business_messaging',
      config_id: '709959344325710', // configuration ID goes here
      response_type: 'code',    // must be set to 'code' for System User access token
      override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
      extras: {
        feature: 'whatsapp_embedded_signup',
        version: 2,
        sessionInfoVersion: 2,
        setup: {
          solutionID: '909888600763336'
        }
      }
    });
	}

  //event: MessageEvent
  sessionInfoListener(event: MessageEvent) {
    if (event.origin === null || event.origin !== "https://www.facebook.com") {
      return;
    }

		try {
      const data = JSON.parse(event.data);
      console.log('Final JSON Data >>', data);

			if (data.type && data.type === 'WA_EMBEDDED_SIGNUP') {
				// if user finishes the Embedded Signup flow
				if (data.event && data.event === 'FINISH') {
          
					const {phone_number_id, waba_id} = data.data;
          console.log("Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);
					
					const bodyData = {
            phone_number_id: '361890597007510',
            waba_id: '306904762515762'
          };

          console.log("Body payload >>", bodyData);

          const url = `https://api.nuacem.com/waba/sp/register`;

          // HTTP method to post the captured phone number and waba id to the mentioned API.
          this.http.post(url, bodyData).subscribe({
            next: data => {
              console.log("Success Data >>", data);
            },
            error: error => {
              const errorMsg = error;
              console.log("Error details >>", errorMsg);
            }
          });
					// this.postJSON(bodyData);
          console.log('Success event', data.data);
				}
        // if user reports an error during the Embedded Signup flow
        else if (data.event && data.event === 'ERROR') {
          const {error_message} = data.data;
          console.error("error ", error_message);
        }
				// if user cancels the Embedded Signup flow
				else {
					const{current_step} = data.data;
					console.log("Cancel event",{current_step});
				}
			}
		} catch {
			// Don’t parse info that’s not a JSON
			console.log('Non JSON Response', event.data);
		}
	}

  // async postJSON(data: any) {
  //   console.log('Data sent for POST>>', data);

  //   const url = `https://api.nuacem.com/waba/sp/register`;

  //   try {
  //     const response = await fetch(url, {
  //       method: 'POST',
  //       headers: {'Content-type': 'application/json'},
  //       body: JSON.stringify(data),
  //       cache: 'default'
  //     });
  //     const result = await response.json();
  //     console.log('Success:', result);
  //   } catch (error) {
  //     console.log('Error:', error);
  //     return error;
  //   }
  // }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.showLoginLoader = false;
  }
}
