import { AfterViewInit, Component, signal, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-channel-information',
  templateUrl: './channel-information.component.html',
  styleUrls: ['./channel-information.component.scss']
})
export class ChannelInformationComponent implements AfterViewInit {
  displayTemplate = signal<TemplateRef<string>>(null);
  channel_name: any;

  @ViewChild('email') private email!: TemplateRef<any>;

  ngAfterViewInit(): void {
    this.displayTemplate.set(this.email);
  }

  gotoChannels(){
    return;
  }
}
